import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { List, Map } from 'immutable';
import { DraggableArea } from '@tradetrax/web-common/lib/Stages/DragNDrop/DraggableArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableRowDropDown } from '@tradetrax/web-common';
import { CHILDREN_STATUS_MAP, ChipStatusToggle, ChipStatus } from '../ChipStatus';
import navigate from 'app/navigate';
import { CriticalPathIcon } from '../CriticalPath';

export const calculateInnerCellHeight = (task, expandedRows) => {
  const isTaskExpanded = !!expandedRows.getIn(['stages', String(task.get('id'))]);

  if (isTaskExpanded) {
    return 61 + task.get('children').size * 50;
  }

  return 70;
};

export const getRowHeightStagesView = (index, viewModel, expandedRows) => {
  if (index < 0) return 0;
  const rowData = viewModel.get(index);
  if (!viewModel.size && !rowData) return 80;

  const isStage = rowData.get('isStage');
  const id = isStage ? rowData.get('_id') : String(rowData.get('id'));
  const isExpanded = !!expandedRows.getIn(['stages', id]);

  if (!isExpanded) return 80; // default row size.

  if (!isStage) {
    // orphan task expanded.
    return 80 + viewModel.getIn([index, 'children']).size * 50;
  }

  if (isStage) {
    const stageTasks = rowData.get('tasks');
    return stageTasks.reduce((size, task) => {
      const isTaskExpanded = !!expandedRows.getIn(['stages', String(task.get('id'))]);
      return isTaskExpanded ? size + task.get('children').size * 47 : size;
    }, stageTasks.size * 75 + 100); // expanded stage => 100, each inner task => 75
  }
};

export const getRowHeightListView = (index, tasks, expandedRows) => {
  if (index < 0) return 0;
  const task = tasks.get(index);
  if (task && !!expandedRows.get(String(task.get('id')))) {
    return 80 + task.get('children').size * 52;
  }
  return 80;
};

export const InnerTasksList = ({
  stage,
  rowIndex,
  controller,
  expandedRows,
  openAddTaskModal,
  isTemplate,
  width,
  getCellHeight,
  dndController,
  getRowIndex,
}) => {
  const tasks = stage.get('tasks');
  const containSubTasks = task => task.get('children').size > 0;

  const onClickExpand = task => {
    controller.toggleRow('stages', task, rowIndex);
  };

  return (
    <>
      {tasks.map((task, index) => {
        const canExpand = containSubTasks(task);
        const isExpanded = expandedRows?.get(`${task.get('id')}`);
        const icon = isExpanded ? 'chevron-up' : 'chevron-down';
        const height = getCellHeight(task);
        const isCritical = task.get('isCritical');
        return (
          <ItemContainer
            key={`task-${index}`}
            className="ml-4"
            height={height}
            data-task-order={task.get('order')}
            data-stage-id={task.get('stageId')}
          >
            <Divider />
            <div className="d-flex flex-row align-items-center">
              <DraggableArea
                task={task}
                controller={dndController}
                rowIndex={getRowIndex(task)}
                className={cn('drag-icon font-size-14 mr-4', { 'pr-4': !canExpand })}
              />{' '}
              {canExpand && <FontAwesomeIcon icon={icon} className="mr-2" onClick={() => onClickExpand(task)} />}
              <Link
                className="main-link"
                to={{
                  pathname: navigate.from.JobDetails.to.JobTaskDetails({ taskId: task.get('id') }, false),
                  state: { referrer: 'schedule' },
                }}
              >
                <TaskNameContainer
                  text={task.get('name')}
                  width={width}
                  isKeyFinish={task.get('isKeyFinish')}
                  isKeyStart={task.get('isKeyStart')}
                  isInner
                  isCritical={isCritical}
                />
              </Link>
            </div>
            {isExpanded && (
              <SubTasksList
                className="ml-5 pl-3"
                subTasks={task.get('children')}
                rowData={task}
                controller={controller}
                isTemplate={isTemplate}
              />
            )}
          </ItemContainer>
        );
      })}
      <button onClick={() => openAddTaskModal(stage)} className="btn btn-link font-size-12 pl-4">
        + Add Task
      </button>
    </>
  );
};

export const SubTasksList = ({ subTasks, rowData, controller, isTemplate = false, className = '' }) => {
  return (
    <div className="cursor-default mt-4">
      {subTasks.map((subTask, index) => {
        const isChecked = isTemplate && subTask.get('addToJob');
        const isCustom = !!subTask.get('accountId');
        return (
          <div
            className={`ml-4 font-size-12 position-relative ${className}`}
            key={`subtask-${index}`}
            style={{ height: '50px' }}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="d-flex align-items-center " style={{ width: '68%' }}>
                {isTemplate && (
                  <input
                    type="checkbox"
                    className="mr-2"
                    defaultChecked={isChecked}
                    style={{ position: 'relative', top: '10px' }}
                    onClick={event => controller.selectSubTask(subTask, rowData, event)}
                  />
                )}
                <span className="font-weight-bold text-truncate" style={{ position: 'relative', top: '11px' }}>
                  {subTask.get('name')}
                  {isCustom && <FontAwesomeIcon icon="house-chimney-user" className="ml-3 text-muted" />}
                </span>
              </div>
              {isChecked && (
                <span className="text-muted pl-2 pr-4 " style={{ position: 'relative', top: '11px', right: '60px' }}>
                  It will appear on the Job
                </span>
              )}
            </div>
            <Divider />
          </div>
        );
      })}
    </div>
  );
};

export const SubTaskStatusList = ({ task, subTasks, controller, readonly }) => (
  <div className="d-flex flex-column py-1" style={{ marginLeft: '-8px', marginTop: `${readonly ? '35px' : '25px'}` }}>
    {subTasks.map((subTask, index) => {
      const status = subTask.get('status') || 'not-started';
      const isCompleted = status === 'completed';
      const Component = readonly ? ChipStatus : ChipStatusToggle;
      const label = !readonly || isCompleted ? CHILDREN_STATUS_MAP[status].label : 'Not Started';
      return (
        <Component
          key={`child-${index}`}
          className="mini-chipstatus"
          showIcon={false}
          status={CHILDREN_STATUS_MAP[status].status}
          onClick={() => !readonly && controller.updateSubTaskStatus(task, subTask)}
          disabled={readonly}
          style={{ marginBottom: '18px', marginTop: '8px' }}
        >
          {label}
        </Component>
      );
    })}
  </div>
);

export const DropDownActionList = ({ tasks, getCellHeight, onSelect }) => (
  <div className="mt-5">
    {tasks.map((task, index) => {
      const height = getCellHeight(task, index);
      return (
        <ItemContainer key={`actions-${index}`} height={height} pt="1.4rem">
          <TableRowDropDown remove onSelect={() => onSelect(task)} />
        </ItemContainer>
      );
    })}
  </div>
);

export const ItemContainer = styled.div`
  ${({ height, pt = '1.5rem' }) => `
    padding-top: ${pt};
    padding-bottom: 1.5rem;
    margin-top: 0.2rem;
    position: relative;
    ${height ? 'height: ' + height + 'px' : ''};
  `}
`;

export const Divider = styled.hr`
  position: relative;
  width: 200vh;
  margin: 0px;
  border-top: 1px solid #ededed;
  bottom: 1.5rem;
  right: 12rem;
`;

// TODO: refactor this function to support more stages
const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
function getStageIndex(index) {
  if (alphabet[index]) {
    return alphabet[index].toUpperCase();
  }
  // only works for a template with less than 53 stages
  return `A${getStageIndex(index - alphabet.length)}`;
}

export function mapStagesViewModel(templateOrJob) {
  const stages = templateOrJob.get('stages');
  const tasks = templateOrJob.get('tasks');
  const orphans = tasks.filter(task => task.get('stageId') === null);
  const above = orphans.filter(task => task.get('orderAboveStages') === true);
  const below = orphans.filter(task => task.get('orderAboveStages') !== true);
  const stagesViewModel = stages
    .map(stage => stage.set('durationDays', stage.get('duration')).set('expectedFinishDate', stage.get('endDate')))
    .reduce((model, stage, index) => {
      return model.push(
        stage
          .set(
            'tasks',
            tasks.filter(task => !task.get('isStageTask') && task.get('stageId') === stage.get('_id'))
          )
          .set('isStage', true)
          .set('children', List())
          .set('assigneeAccount', Map())
          .set('predecessors', stage.get('predecessors') || List())
          .set('letterIndex', getStageIndex(index)) // templates compliance
          .set('stageIndex', getStageIndex(index))
      );
    }, List());

  return above.push(...stagesViewModel.toArray()).push(...below.toArray());
}

export const TaskNameContainer = ({ width, text, isInner, isKeyFinish, isKeyStart, isCritical }) => {
  let widthAux = width - 55;
  const isKey = isKeyFinish || isKeyStart;
  if (isKeyFinish) widthAux -= 20;
  if (isKeyStart) widthAux -= 20;
  if (!(isInner && isKey)) widthAux -= 40;

  return (
    <div className="d-flex flex-row" style={{ maxWidth: width + 14 }}>
      <StyledTaskNameContainer className="font-weight-bold font-size-14 text-truncate" width={widthAux}>
        {text}
      </StyledTaskNameContainer>
      {isCritical && <CriticalPathIcon />}
      {isKeyStart ? <FontAwesomeIcon icon="flag" className="text-blue-300 ml-2" /> : null}
      {isKeyFinish ? <FontAwesomeIcon icon="flag" className="text-orange ml-2" /> : null}
    </div>
  );
};

const StyledTaskNameContainer = styled.div`
  ${({ width }) => `
    max-width: ${width}px;
  `}
`;
