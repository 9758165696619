import React, { useState } from 'react';
import cn from 'classnames';
import { Redirect, Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { SecondaryButton, ToggleButtons, ToggleButton, ShowMoreUsersModal, IconButton } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { Attachments } from '@tradetrax/web-common/lib/Photos/Attachments/Attachments';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';
import { ScheduleStagesView } from './JobScheduleStagesView';
import { ScheduleListView } from './JobScheduleListView';
import { JobFeed } from './JobFeed';
import { JobSettings } from './JobSettings';
import { JobHeader } from './JobHeader/JobHeader';
import { GRID_VIEW, LIST_VIEW, STAGES_VIEW } from './JobDetailsContext';
import { buildersService } from 'services';
import navigate, { Paths } from 'app/navigate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JobDropDownMenu } from './JobDropDownMenu';
import { CalendarView } from '@tradetrax/web-common/lib/CalendarView';
import { useAppContext } from 'app/App.context';
import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { PlaceHolder } from '@tradetrax/web-common/lib/LoadingPlaceholder';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

export default function JobDetails({ match, jobContext }) {
  const appContext = useAppContext();
  const { jobId } = match.params;
  const [isSettingsOpen, setOpenSettings] = useState(false);
  const {
    state,
    controller,
    account,
    user,
    modal,
    feedLoaderRef,
    hasPermission,
    assignees,
    attachmentsRef,
    filterContext,
  } = jobContext;
  const {
    job,
    tasks,
    feed: jobFeed,
    feedCount: jobFeedCount,
    taskTypes,
    attachmentsView,
    isLoading,
    tab,
  } = state.toObject();

  const lookups = { taskTypes, assignees };

  const { isFiltering } = filterContext;
  const filter = filterContext.filterState.get('values');
  const hasPermissionForStatusAndRelease = hasPermission('job_status_and_release');
  const allJobTasks = state.get('tasks');
  const isReadyForRelease =
    allJobTasks.size > 0 && allJobTasks.every(task => !!task.get('isReady')) && hasPermissionForStatusAndRelease;
  const canChangeJobStatus =
    allJobTasks.size > 0 && allJobTasks.every(task => !!task.get('hasDatesSet')) && hasPermissionForStatusAndRelease;

  const [attachmentsCount, setAttachmentsCount] = React.useState(0);

  if (!job) return <Redirect to={Paths.Job} />;
  const isReleased = job.get('released');
  const isTemplateActive = job.get('templateStatus') === 'active';
  const templateId = job.get('templateId');
  const isGridView = state.get('attachmentsView') === GRID_VIEW;
  // TODO: this is always creating the components but only rendering one!
  // replace for a factory approach instead.
  const ScheduleViews = {
    [LIST_VIEW]: <ScheduleListView jobContext={jobContext} isFilterSet={isFiltering} />,
    [STAGES_VIEW]: <ScheduleStagesView jobContext={jobContext} isFilterSet={isFiltering} />,
    [CALENDAR_VIEW]: (
      <CalendarView
        filter={filter}
        appContext={appContext}
        calendarService={buildersService}
        navigate={navigate}
        jobId={jobId}
      />
    ),
  };

  const handleAddTask = () => controller.openAddTaskModal({ jobContext });
  const handleShowMoreUsers = () => {
    const { supers, schedulers } = job.toObject();
    const title = `Job: ${job.get('name')}`;
    const community = `Community: ${job.get('communityName')}`;
    modal.open(ShowMoreUsersModal, { supers, schedulers, title, community });
  };

  if (!state.get('hasPermission')) return <Redirect to={Paths.NotPermission} />;

  return (
    <>
      <div className="d-flex">
        {isLoading ? (
          <div className="mt-2">
            <PlaceHolder className="mx-4 mt-4" style={{ width: 112 }} />
          </div>
        ) : (
          <Breadcrumb className="mx-4 mt-4" tag="nav">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Community }}>
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: navigate.to.CommunityDetails({ communityId: job.get('communityId') }, false) }}
            >
              {job.get('communityName')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{job.get('name')}</Breadcrumb.Item>
          </Breadcrumb>
        )}
        {isReleased && (
          <div className="ml-auto font-size-16 mt-4 mr-4 mb-4">
            <FontAwesomeIcon icon="circle-check" className=" text-success" />{' '}
            <span className="text-success ml-1 font-weight-bold">Job Released</span>
          </div>
        )}
      </div>
      {isSettingsOpen && (
        <JobSettings
          controller={controller}
          setOpenSettings={setOpenSettings}
          settings={job.get('settings')}
          jobId={job.get('_id')}
        />
      )}
      <JobHeader
        job={job}
        updateJob={controller.updateJob}
        canChangeJobStatus={canChangeJobStatus}
        className="mx-4 mb-2"
        isReleased={isReleased}
        isReadyForRelease={isReadyForRelease}
        isTemplateActive={isTemplateActive}
        onReleaseClick={controller.releaseToConstruction}
        onSelectStatus={status => controller.updateJobStatus({ status })}
        controller={controller}
        onShowMoreUsers={handleShowMoreUsers}
        isSettingsOpen={isSettingsOpen}
        setOpenSettings={setOpenSettings}
        templateLink={navigate.to.TemplateDetails({ templateId }, false)}
        isLoading={isLoading}
      />
      <div className="d-flex flex-row justify-content-between  mr-4 mb-2">
        <ToggleButtons onChange={controller.setTab} type="radio" name="options" value={tab}>
          <ToggleButton value="schedule">Schedule</ToggleButton>
          <ToggleButton value="feed">Job Feed</ToggleButton>
          <ToggleButton value="attachments">Attachments ({attachmentsCount})</ToggleButton>
        </ToggleButtons>
        <div className={cn('d-none', { 'd-flex': tab === 'attachments' })}>
          <IconButton
            className="active cursor-pointer"
            icon="plus"
            onClick={() => attachmentsRef.current?.addAttachment()}
          />
          <IconButton
            className="active-on-hover cursor-pointer ml-3"
            icon={isGridView ? 'list' : 'grid'}
            onClick={() => {
              controller.toggleAttachmentsView('job-details');
            }}
          />
        </div>
      </div>
      <FilterSets.Panel filterContext={filterContext} visible={tab === 'schedule'}>
        <SecondaryButton className="mr-3 text-nowrap" onClick={handleAddTask}>
          Add Task
        </SecondaryButton>
        <JobDropDownMenu toggleView={controller.toggleScheduleView} />
        <FilterImplementation filterContext={filterContext} lookups={lookups} className="size-lg" showAdvanced>
          <FilterSets.Section filterContext={filterContext} />
        </FilterImplementation>
      </FilterSets.Panel>
      {tab === 'schedule' && ScheduleViews[state.get('scheduleView').view || STAGES_VIEW]}
      {tab === 'feed' && (
        <JobFeed
          jobFeed={jobFeed}
          account={account}
          user={user}
          loadMoreRows={params => controller.loadMoreJobFeedRows(jobId, params)}
          loaderRef={feedLoaderRef}
          jobFeedCount={jobFeedCount}
          tasks={tasks}
          appContext={jobContext.appContext}
        />
      )}
      <Attachments
        job={job}
        visible={tab === 'attachments'}
        appContext={jobContext.appContext}
        setAttachmentsCount={setAttachmentsCount}
        service={buildersService}
        view={attachmentsView}
        ref={attachmentsRef}
      />
    </>
  );
}

export const noRowsRenderer = (isFiltering, isLoading) => () => {
  if (isLoading) return;
  if (isFiltering)
    return (
      <EmptyStateCenterContainer top="280px">
        <EmptyState
          icon="wrench"
          title="No Matches Found"
          body="When there's a Task that meets the filters, it will appear here."
        />
      </EmptyStateCenterContainer>
    );
  return (
    <EmptyStateCenterContainer top="280px">
      <EmptyState
        icon="wrench"
        title="No Tasks or Stages Added"
        body="When you add a Task or a Stage on this Job it will appear here."
      />
    </EmptyStateCenterContainer>
  );
};
