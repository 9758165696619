import React, { useEffect, useRef } from 'react';
import navigate from 'app/navigate';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { STATUS_MAP, ChipStatus, EMPTY_ROW, renderCell, OnTime } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { mongoToTrx, getAssigneeBadgeClassName, handleTableScrollStickyColumn2 } from '@tradetrax/web-common/lib/utils';
import { COL_WIDTHS, WIDTHS, linkToTask } from './shared';
import { MINIMUM_BATCH_SIZE } from './TasksPageContext';

export const CompletedTasksTable = ({ state, controller, account }) => {
  const scrollDivRef = useRef(null);
  const { tasks, totalCount, maxCount } = state.get('completed').toObject();
  const rowClassName = rowData => (!rowData || rowData === EMPTY_ROW ? 'loading' : '');
  const showDaysBehind = false;

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);

  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={controller.loadMoreCompletedTasks}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div ref={scrollDivRef} style={{ flex: '1 1 auto', overflowX: 'auto' }}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1200 ? width : 1200;

                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    onRowClick={() => {}}
                    overscanRowCount={2}
                    estimatedRowSize={80}
                    rowHeight={80}
                    rowCount={Math.min(maxCount, totalCount)}
                    rowClassName={({ index }) => rowClassName(tasks.get(index))}
                    rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top="250px">
                        <EmptyState
                          icon="circle-check"
                          title="No Completed Tasks"
                          body="When a task is completed it will appear here."
                        />
                      </EmptyStateCenterContainer>
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                    <Column
                      label="Task Name"
                      dataKey="name"
                      minWidth={250}
                      width={minWidth - WIDTHS}
                      cellRenderer={renderCell(({ cellData: taskName, rowData: task }) => (
                        <Link className="main-link" to={linkToTask(task, 'completed')}>
                          <div className="text-truncate">{taskName}</div>
                        </Link>
                      ))}
                    />
                    <Column
                      label="Job"
                      dataKey="job"
                      width={COL_WIDTHS.jobName}
                      cellRenderer={renderCell(({ cellData }) => {
                        const name = cellData.get('name');
                        const lotNumber = cellData.get('lotNumber');
                        const jobId = cellData ? cellData.get('id') : 'aaaabbbbccccdddd';
                        return (
                          <>
                            <Link className="secondary-link" to={navigate.to.JobDetails({ jobId }, false)}>
                              <div className="text-truncate">{name}</div>
                            </Link>
                            {lotNumber && (
                              <span className="d-block text-muted text-truncate">{`LOT #${lotNumber}`}</span>
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Account Assignee"
                      dataKey="assigneeAccount"
                      width={COL_WIDTHS.assignee}
                      cellRenderer={renderCell(({ cellData: assigneeAccount }) => (
                        <span
                          style={{ maxWidth: COL_WIDTHS.assignee - 12 }}
                          className={`text-truncate not-permission ${getAssigneeBadgeClassName(
                            assigneeAccount.get('companyId'),
                            account.get('_id')
                          )}`}
                        >
                          {assigneeAccount.get('company') || 'Needs Assignment'}
                        </span>
                      ))}
                    />
                    <Column
                      label="Duration"
                      dataKey="durationDays"
                      width={COL_WIDTHS.duration}
                      cellRenderer={renderCell(({ cellData }) => `${cellData}d`)}
                    />
                    <Column
                      label="Exp. Start"
                      dataKey="startDate"
                      width={COL_WIDTHS.expStart}
                      cellRenderer={renderCell(({ cellData: startDate }) => (
                        <span>{mongoToTrx(startDate) || 'MM/DD/YY'}</span>
                      ))}
                    />
                    <Column
                      label="Exp. Finish"
                      dataKey="expectedFinishDate"
                      width={COL_WIDTHS.expFinish}
                      cellRenderer={renderCell(({ cellData: finishDate }) => (
                        <span>{mongoToTrx(finishDate) || 'MM/DD/YY'}</span>
                      ))}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ rowData, cellData }) => (
                        <div className="d-flex flex-column align-items-center">
                          <ChipStatus {...STATUS_MAP[cellData]} />
                          {showDaysBehind && <OnTime onTime={rowData.get('daysBehind')} hideOnSchedule />}
                        </div>
                      ))}
                    />
                    <Column label="" dataKey="" width={COL_WIDTHS.action} />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
