import { markAsSideEffect } from '../useController';
import { formatTasks } from './common';

const body = {};

markAsSideEffect(sendUpdateRequests);
export function sendUpdateRequests(selectedTasks) {
  const tasks = formatTasks(selectedTasks);
  this.bulkService
    .acceptFinishDateRequestMany({ tasks, body })
    .then(() => this.alert.success({ message: 'Update Request from Installer successfully sent to Builder.' }))
    .then(() => this.controller.clearSelectedTasks())
    .then(() => this.parentContext.controller.readUpdateRequests())
    .catch(error =>
      this.alert.error({ message: 'There was a problem sending this update request. Please try again.' })
    );
}

markAsSideEffect(declineUpdateRequests);
export function declineUpdateRequests(selectedTasks) {
  const tasks = formatTasks(selectedTasks);
  this.bulkService
    .rejectFinishDateRequestMany({ tasks, body })
    .then(() => this.alert.success({ message: 'Update request declined. Installer user will be notified.' }))
    .then(() => this.controller.clearSelectedTasks())
    .then(() => this.parentContext.controller.readUpdateRequests())
    .catch(error =>
      this.alert.error({
        message: 'There was a problem declining this update request from the Installer. Please try again.',
      })
    );
}
