import React from 'react';
import { FilterTrigger } from '../Components/Filter.trigger';
import { FilterPopup } from '../Components/Filter.popup';
import { getFilterComponent } from './filter.factory';

// TODO: rename this component... <Filter />
export const FilterImplementation = ({ filterContext, lookups, children, ...props }) => {
  const { filterState, filterName } = filterContext;
  const Filter = getFilterComponent(filterName);
  const { isFiltering } = filterContext;
  const { title, top, floatToRight, className = '', showAdvanced } = props;

  if (!Filter) throw Error(`${filterName} Filter not implemented!`);

  return (
    <FilterTrigger {...props} isFiltering={isFiltering}>
      <FilterPopup top={top} floatToRight={floatToRight} className={className}>
        <Filter
          filterName={filterName}
          filterContext={filterContext}
          lookups={lookups}
          title={title}
          showAdvanced={showAdvanced}
        >
          {children}
        </Filter>
      </FilterPopup>
    </FilterTrigger>
  );
};
