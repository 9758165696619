import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItemContainer, SubTaskStatusList } from '@tradetrax/web-common/lib/Stages';
import {
  getAssigneeBadgeClassName,
  datepickerFormat,
  mongoToTrx,
  isoToTrxFull,
  isTaskOverdue,
  getMinEndDate,
  NOT_STARTED,
  COMPLETED,
  IN_PROGRESS,
} from '@tradetrax/web-common/lib/utils';
import { STATUS_MAP, ChipStatusDropdown, OnTime, Typeahead } from '@tradetrax/web-common';
import { DatepickerWithPortal } from '../DatepickerWithPortal';
import { TaskDurationInput } from '../../TaskDurationInput';
import WarningCriticalMsg, {
  CriticalDay,
  criticalClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';

export const AssigneeList = ({
  tasks,
  accountId,
  getCellHeight,
  onEditFieldClick,
  editingTaskId,
  companies,
  defaultOpen,
  controller,
  maxWidth,
}) => {
  return (
    <div className="mt-4 pt-3">
      {tasks.map((task, index) => {
        const companyId = task.getIn(['assigneeAccount', 'companyId']);
        const isAssigned = !!companyId;
        const height = getCellHeight(task);
        const isEditing = editingTaskId === task.get('id');
        if (isEditing && defaultOpen) {
          const company = companies.find(c => c.get('subAccountId') === companyId);
          const selected = company ? [company] : [];
          return (
            <ItemContainer key={`assignee-${task.get('id')}`} height={height}>
              <Typeahead
                id={`account-assignee-${index}`}
                defaultValue={task.getIn(['assigneeAccount', 'company'])}
                placeholder="Needs Assignment"
                options={companies}
                labelKey={option => option.get('name')}
                selected={selected}
                flip={true}
                defaultOpen={defaultOpen}
                isMyId={option => accountId === option.get('subAccountId')}
                filterSelected={(option, selected) => selected.get('subAccountId') !== option.get('subAccountId')}
                onChange={([company]) =>
                  controller.assignTask({
                    task,
                    subId: company ? company.get('subAccountId') : null,
                  })
                }
              />
            </ItemContainer>
          );
        }
        return (
          <ItemContainer key={`assignee-${task.get('id')}`} height={height}>
            <AssigneeBadge
              maxWidth={maxWidth}
              onClick={e => onEditFieldClick(task.get('id'), 'assigneeAccount')(e)}
              className={`text-truncate ${getAssigneeBadgeClassName(companyId, accountId)}`}
            >
              {isAssigned ? task.getIn(['assigneeAccount', 'company']) : 'Needs Assignment'}
              <FontAwesomeIcon icon="chevron-down" className="ml-1" />
            </AssigneeBadge>
          </ItemContainer>
        );
      })}
    </div>
  );
};

export const ConfirmedList = ({ tasks, getCellHeight }) => {
  return (
    <div className="mt-4 pt-3">
      {tasks.map(task => {
        const isConfirmed = !!task.get('startDateConfirmed');
        const height = getCellHeight(task);
        return (
          <ItemContainer key={`confirmed-${task.get('id')}`} height={height}>
            <div
              className={cn('text-right mr-5 pr-2', {
                'text-success': isConfirmed,
                'text-gray-200': !isConfirmed,
              })}
            >
              <FontAwesomeIcon icon="check" />
            </div>
          </ItemContainer>
        );
      })}
    </div>
  );
};

export const StartDateList = ({
  tasks,
  getCellHeight,
  canEdit,
  calendarRef,
  editingTaskId,
  onEditFieldClick,
  controller,
  defaultEditField,
}) => {
  return (
    <div className="mt-4">
      {tasks.map(task => {
        const height = getCellHeight(task);
        const taskStartDate = task.get('startDate');
        const startDate = taskStartDate ? datepickerFormat(mongoToTrx(taskStartDate)) : '';
        const textStartDate = startDate ? isoToTrxFull(taskStartDate) : 'MM/DD/YY';
        const { isOverdueStart } = isTaskOverdue(task);
        const isEditing = editingTaskId === task.get('id');

        const lateStartDate = task.get('lateStartDate') || undefined;
        const criticalStartDate = new Date(lateStartDate);
        return (
          <ItemContainer key={`start-date-${task.get('id')}`} height={height}>
            <DatepickerWithPortal
              name="startDate"
              date={startDate}
              isDefaultOpen={isEditing && defaultEditField === 'startDate'}
              onChange={date => controller.updateTaskStartDate({ date, task })}
              onChangeRaw={({ target }) => controller.updateRawStartDate({ date: target.value, task })}
              isOverdue={isOverdueStart}
              dateText={textStartDate}
              isEditing={isEditing & canEdit}
              canEdit={canEdit}
              portalRoot={calendarRef}
              onClick={onEditFieldClick(task.get('id'), 'startDate')}
              dayClassName={date => criticalClass(date, criticalStartDate, startDate)}
              renderDayContents={(day, date) => CriticalDay(day, date, criticalStartDate, startDate)}
              calendarClassName="react-datepicker__critical"
            >
              <WarningCriticalMsg showMsg={showWarningCriticalMsg(startDate, criticalStartDate)} />
            </DatepickerWithPortal>
          </ItemContainer>
        );
      })}
    </div>
  );
};

export const EndDateList = ({
  tasks,
  getCellHeight,
  canEdit,
  calendarRef,
  editingTaskId,
  onEditFieldClick,
  controller,
  defaultEditField,
}) => (
  <div className="mt-4">
    {tasks.map(task => {
      const height = getCellHeight(task);
      const taskEndDate = task.get('expectedFinishDate');
      const minEndDate = getMinEndDate(task.get('startDate'));
      const { isOverdueFinish } = isTaskOverdue(task);
      const endDate = taskEndDate ? datepickerFormat(mongoToTrx(taskEndDate)) : '';
      const textEndDate = endDate ? isoToTrxFull(endDate) : 'MM/DD/YY';
      const isEditing = editingTaskId === task.get('id');

      const lateEndDate = task.get('lateEndDate') || undefined;
      const criticalEndDate = new Date(lateEndDate);
      return (
        <ItemContainer key={`end-date-${task.get('id')}`} height={height}>
          <DatepickerWithPortal
            name="endDate"
            date={endDate}
            minDate={minEndDate}
            isDefaultOpen={isEditing && defaultEditField === 'endDate'}
            onChange={date => controller.updateEndDateAndDuration({ date, task })}
            onChangeRaw={event => controller.updateRawEndDate({ date: event.target.value, task })}
            isOverdue={isOverdueFinish}
            dateText={textEndDate}
            isEditing={isEditing && canEdit}
            canEdit={canEdit}
            portalRoot={calendarRef}
            onClick={onEditFieldClick(task.get('id'), 'endDate')}
            dayClassName={date => criticalClass(date, criticalEndDate, endDate)}
            renderDayContents={(day, date) => CriticalDay(day, date, criticalEndDate, endDate)}
            calendarClassName="react-datepicker__critical"
          >
            <WarningCriticalMsg showMsg={showWarningCriticalMsg(endDate, criticalEndDate)} />
          </DatepickerWithPortal>
        </ItemContainer>
      );
    })}
  </div>
);

export const InnerDurationList = ({ tasks, getCellHeight, editingTaskId, onEditFieldClick, canEdit, controller }) => (
  <div className="mt-4">
    {tasks.map(task => {
      const height = getCellHeight(task);
      const isEditing = canEdit && editingTaskId === task.get('id');
      return (
        <ItemContainer key={`duration-${task.get('id')}`} height={height}>
          {isEditing ? (
            <TaskDurationInput controller={controller} task={task} />
          ) : (
            <span
              onClick={onEditFieldClick(task.get('id'), 'durationDays')}
              className={cn('d-block pr-4 ', { editable: canEdit })}
            >
              {task.get('durationDays')}d
            </span>
          )}
        </ItemContainer>
      );
    })}
  </div>
);

export const PredecessorsList = ({ tasks, innerTasks, canEdit, changeDependencies, getCellHeight }) => (
  <div className="mt-4">
    {innerTasks.map(task => {
      const height = getCellHeight(task);
      const predecessors = task.get('predecessors');
      const predecessorsString = predecessors
        .map(p => {
          const taskAux = tasks.find(t => t.get('id') === p.get('taskId'));
          const depType = taskAux && p.get('dependencyType') !== 'FS' ? p.get('dependencyType') : '';
          return taskAux ? depType + taskAux.get('rowIndex') : '';
        })
        .toJS()
        .join();
      return (
        <ItemContainer key={`confirmed-${task.get('id')}`} height={height}>
          <span className={cn({ editable: canEdit })} onClick={() => canEdit && changeDependencies(task)}>
            {predecessorsString && (
              <>
                <span>{predecessorsString}</span>
                {canEdit && <FontAwesomeIcon icon="pen" />}
              </>
            )}
            {!predecessorsString && canEdit && <FontAwesomeIcon icon="pen-to-square" />}
          </span>
          {task.get('missingReference') && (
            <div className="text-danger" style={{ marginLeft: '-10px' }}>
              Missing
            </div>
          )}
        </ItemContainer>
      );
    })}
  </div>
);

export const StatusList = ({ tasks, getCellHeight, controller, expandedRows, showDaysBehind = false }) => (
  <div className="mt-2">
    {tasks.map(task => {
      const status = task.get('status');
      const isExpanded = expandedRows?.get(`${task.get('id')}`);
      const height = getCellHeight(task);
      return (
        <ItemContainer
          key={`status-${task.get('id')}`}
          height={height}
          className="d-flex flex-column align-items-center"
        >
          <ChipStatusDropdown
            {...STATUS_MAP[status]}
            notStarted={status !== NOT_STARTED}
            progress={status !== IN_PROGRESS}
            completed={status !== COMPLETED}
            onSelect={status => controller.updateStatus({ task, status })}
          />
          {showDaysBehind && (
            <span className="position-absolute mt-4 pt-2">
              <OnTime onTime={task.get('daysBehind')} hideOnSchedule />
            </span>
          )}
          {isExpanded && (
            <SubTaskStatusList
              className="ml-5 pl-3"
              subTasks={task.get('children')}
              task={task}
              controller={controller}
            />
          )}
        </ItemContainer>
      );
    })}
  </div>
);

export const AssigneeBadge = styled.span`
  ${({ maxWidth }) => `
    max-width: ${maxWidth - 17}px;
    &:hover {
      max-width: ${maxWidth}px;
    }
  `}
`;
