import React from 'react';

import { TaskDefaultContent } from './TaskDefaultContent';
import { TaskExpFinishRequest } from './TaskExpFinishRequest';
import { TaskExpStartRequest } from './TaskExpStartRequest';

const DEFAULT_CONTENT = 'default_content';

const componentsMap = {
  'new-expected-end-date': TaskExpFinishRequest,
  'new-start-date-request': TaskExpStartRequest,
};

export function TaskDetailsContent({ task, controller, jobContext }) {
  const renderType = task.getIn(['changeRequest', 'activeForCurrentUser'])
    ? task.getIn(['changeRequest', 'type'])
    : DEFAULT_CONTENT;

  const Component = componentsMap[renderType] || TaskDefaultContent;

  return <Component task={task} controller={controller} jobContext={jobContext} />;
}
