import React from 'react';
import styled from 'styled-components';
import { MyTasks } from './MyTasks';
import { RelevantTasks } from './RelevantTasks';
import { LeadTime } from './LeadTime';
import { colors } from '@tradetrax/web-common';
import { MY_TASKS, RELEVANT_TASKS, LEAD_TIME } from '../CustomRoleDetails.common';

const Tabs = styled.div`
  border-right: 1px solid #e0e2e7;
`;

const Tab = styled.div`
  font-size: 16px;
  line-height: 22px;
  padding: 0.25rem 0;
  margin: 0.75rem 0;
  cursor: pointer;
  font-weight: ${props => (props.isSelected ? '700' : '400')};
  color: ${props => (props.isSelected ? colors.blue300 : colors.gray400)};
  &:hover {
    font-weight: ${props => (props.isSelected ? '700' : '400')};
    color: ${colors.blue400};
    text-decoration: underline;
  }
`;

export const Notifications = ({ ...props }) => {
  const { state, controller } = props;
  const { selectedSection, customRole } = state.toObject();
  const changeSection = section => controller.setSelectedSection(section);

  return (
    <div className="d-flex flex-column">
      <span className="text-gray-800 px-4">
        These Notifications will be applied when creating new users with this Custom Role. Changes won’t impact existing
        users.
      </span>
      <div className="d-flex flex-row w-100 px-4 py-5">
        <Tabs className="w-25">
          <Tab isSelected={selectedSection === MY_TASKS} onClick={() => changeSection(MY_TASKS)}>
            My Tasks
          </Tab>
          <Tab
            data-testid="tab-relevant-tasks"
            isSelected={selectedSection === RELEVANT_TASKS}
            onClick={() => changeSection(RELEVANT_TASKS)}
          >
            Other Relevant Tasks
          </Tab>
          <Tab
            data-testid="tab-lead-time-tasks"
            isSelected={selectedSection === LEAD_TIME}
            onClick={() => changeSection(LEAD_TIME)}
          >
            Lead Time
          </Tab>
        </Tabs>
        <div className="w-50 ml-5">
          {selectedSection === MY_TASKS ? <MyTasks controller={controller} customRole={customRole} /> : null}
          {selectedSection === RELEVANT_TASKS ? <RelevantTasks {...props} /> : null}
          {selectedSection === LEAD_TIME ? <LeadTime {...props} /> : null}
        </div>
      </div>
    </div>
  );
};
