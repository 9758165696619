import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { colors } from '../theme';
import { mongoToText } from '../utils';

export const PopoverApprovalStatus = ({ task }) => {
  const builderName = task.getIn(['job', 'builderName']);
  const isChangeRequestOnReviewByTrade =
    task.getIn(['changeRequest', 'state']) === 'review-by-sub' &&
    task.getIn(['changeRequest', 'activeForCurrentUser']) &&
    !task.get('startDateConfirmationRequest');
  const reviewByBuilderState = task.getIn(['changeRequestOnReviewByBuilder', 'state']);
  const rejectedByBuilderState =
    task.getIn(['changeRequestForStartDateConfirmation', 'state']) === 'rejected-by-builder';
  const isChangeRequestOnReviewByBuilder = reviewByBuilderState === 'review-by-builder';
  const isChangeRequestRejectedByBuilder = rejectedByBuilderState || reviewByBuilderState === 'rejected-by-builder';

  if (
    !task.get('changeRequestOnReviewByBuilder') ||
    (!isChangeRequestOnReviewByTrade && !isChangeRequestOnReviewByBuilder && !isChangeRequestRejectedByBuilder)
  )
    return null;

  const { changeRequestType, changeRequestDate } = getChangeRequestData(task);

  const popover = (
    <Popover>
      <Popover.Content>
        <PendingTooltip>
          <strong className="PendingTooltip_date mb-2 ">{`Proposed ${changeRequestType} ${mongoToText(
            changeRequestDate
          )} `}</strong>
          {isChangeRequestOnReviewByBuilder ? (
            <div className="PendingTooltip_msg d-flex justify-content-start align-items-center">
              <FontAwesomeIcon icon="clock" className="mr-2" />
              <span>Pending approval on </span>
            </div>
          ) : isChangeRequestRejectedByBuilder ? (
            <div className="PendingTooltip_msg d-flex justify-content-start align-items-center">
              <FontAwesomeIcon icon="exclamation-circle" className="mr-2 text-danger" />
              <span>Declined by </span>
            </div>
          ) : null}
          <span className="PendingTooltip_builder">{builderName}</span>
        </PendingTooltip>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={popover} open>
      <PendingStatusContainer>
        <>
          <FontAwesomeIcon
            className={`mr-2 ${isChangeRequestOnReviewByBuilder ? '' : 'text-danger'}`}
            icon={isChangeRequestOnReviewByBuilder ? 'clock' : 'exclamation-circle'}
          />
          <span>{isChangeRequestOnReviewByBuilder ? 'Pending approval' : 'Request declined'}</span>
        </>
      </PendingStatusContainer>
    </OverlayTrigger>
  );
};

function getChangeRequestData(task) {
  const type = task.getIn(['changeRequestOnReviewByBuilder', 'type']) || task.getIn(['changeRequest', 'type']);

  let changeRequestType = 'Finish';
  let changeRequestDate =
    task.getIn(['changeRequestOnReviewByBuilder', 'proposedFinishDate']) ||
    task.getIn(['changeRequest', 'proposedFinishDate']);

  if (type === 'new-start-date-request') {
    changeRequestType = 'Start';
    changeRequestDate =
      task.getIn(['changeRequestOnReviewByBuilder', 'proposedStartDate']) ||
      task.getIn(['changeRequest', 'proposedStartDate']) ||
      task.getIn(['changeRequestForStartDateConfirmation', 'newStartDate']);
  }

  return { changeRequestDate, changeRequestType };
}

const PendingStatusContainer = styled.div`
  background-color: ${colors.gray100};
  border-radius: 20px;
  color: ${colors.gray400};
  font-size: 12px;
  padding: 5px 8px;
  margin-top: 9px;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`;

const PendingTooltip = styled.div`
  color: ${colors.gray800};
  font-size: 12px;
  padding: 5px 17px 10px 10px;
  .PendingTooltip_msg {
    margin-top: 3px;
    color: ${colors.gray400};
  }
`;
