import React from 'react';
import { PrimaryButton } from '@tradetrax/web-common';

export const Header = ({ controller }) => (
  <div className="d-flex flex-row">
    <p className=" w-75">
      With Custom Roles you can set default configurations for the different types of customers you may have. Create,
      edit and apply these pre-configured settings for your new users.
    </p>
    <PrimaryButton className="ml-auto mr-5" onClick={() => controller.openCreateRoleModal()}>
      Create Custom Role
    </PrimaryButton>
  </div>
);
