import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import cn from 'classnames';

import navigate from 'app/navigate';
import { ChipStatus, STATUS_MAP } from '@tradetrax/web-common/lib/ChipStatus';
import { StatusDataOnTime, EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import {
  mongoToTrx,
  IN_PROGRESS,
  NOT_READY_TO_START,
  NOT_STARTED,
  handleTableScrollStickyColumn2,
} from '@tradetrax/web-common/lib/utils';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';

const COL_WIDTHS = {
  span: 16 * 2,
  lotNumber: 16 * 10,
  expStart: 16 * 7,
  expFinish: 16 * 7,
  progress: 16 * 14,
  status: 160,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const CommunityJobs = ({ controller, totalCount, jobs, communityId }) => {
  const loadMoreRows = params => controller.loadMoreRows({ ...params, communityId });
  const getProgressStatus = job => {
    const status = job.get('status');
    if (status === IN_PROGRESS) return <StatusDataOnTime data={job.get('statusData')} />;
    if (status === NOT_READY_TO_START || status === NOT_STARTED) return STATUS_MAP[NOT_STARTED].label;
    // status === completed
    return <StatusDataOnTime data={job.get('statusData')} />;
  };
  const scrollDivRef = useRef(null);

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);
  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!jobs.get(index)}
        loadMoreRows={loadMoreRows}
        rowCount={totalCount}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div ref={scrollDivRef} style={{ flex: '1 1 auto', overflowX: 'auto' }}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1200 ? width : 1200;
                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    onRowClick={() => {}}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={totalCount}
                    rowClassName={({ index }) => (jobs.get(index) ? '' : 'loading')}
                    rowGetter={({ index }) => jobs.get(index) || EMPTY_ROW}
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top="250px">
                        <EmptyState
                          icon="house"
                          title="No Jobs Created"
                          body="When you create a Job on this Community it will appear here."
                        />
                      </EmptyStateCenterContainer>
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column label="" dataKey="" width={COL_WIDTHS.span} />
                    <Column
                      label="Job Name"
                      dataKey="name"
                      minWidth={250}
                      width={minWidth - WIDTHS - COL_WIDTHS.span}
                      cellRenderer={renderCell(({ cellData, rowIndex }) => (
                        <Link
                          className="main-link"
                          to={{
                            pathname: navigate.to.JobDetails({ jobId: jobs.getIn([rowIndex, '_id']) }, false),
                            state: {
                              referrer: 'communities',
                              defaultTab: 'schedule',
                            },
                          }}
                        >
                          <div className="text-truncate">{cellData}</div>
                        </Link>
                      ))}
                    />
                    <Column
                      label="LOT #"
                      dataKey="lotNumber"
                      width={COL_WIDTHS.lotNumber}
                      className="overflow-hidden"
                      cellRenderer={renderCell(({ cellData }) => (
                        <span className={cn('d-block text-truncate', { 'text-gray-200': cellData === null })}>
                          {cellData === null ? 'Not Set' : cellData}
                        </span>
                      ))}
                    />
                    <Column
                      label="Exp. Start"
                      dataKey="startDate"
                      width={COL_WIDTHS.expStart}
                      cellRenderer={renderCell(({ cellData }) => (
                        <span>{mongoToTrx(cellData) || 'MM/DD/YY'}</span>
                      ))}
                    />
                    <Column
                      label="Exp. Finish"
                      dataKey="expectedFinishDate"
                      width={COL_WIDTHS.expFinish}
                      cellRenderer={renderCell(({ cellData }) => (
                        <span>{mongoToTrx(cellData) || 'MM/DD/YY'}</span>
                      ))}
                    />
                    {/* <Column
                    label="Task Assigned"
                    dataKey="numberOfTasks"
                    width={colWidth}
                    cellRenderer={({ cellData }) => {
                      // TODO read open/active tasks
                      return (
                        <>
                          <div>{`${cellData || 0} tasks`}</div>
                          <small>0 open / 0 active</small>
                        </>
                      );
                    }}
                  /> */}
                    <Column
                      label="Progress"
                      dataKey="progress"
                      width={COL_WIDTHS.progress}
                      cellRenderer={renderCell(({ cellData, rowData }) => (
                        <>
                          <div>{getProgressStatus(rowData)}</div>
                          <small>{`${Math.floor(cellData)}% progress`}</small>
                        </>
                      ))}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ cellData }) => (
                        <ChipStatus {...STATUS_MAP[cellData]} className="mx-2" />
                      ))}
                    />
                    <Column label="" dataKey="" width={COL_WIDTHS.span} />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
