import { debounce } from 'lodash';
import { fromJS } from 'immutable';
import { markAsSideEffect } from '@tradetrax/web-common/lib/useController';

const RT_JOB_ACTIONS = {
  CREATE_TASK: 'craete-task',
  UPDATE_TASK: 'update-task',
  DELETE_TASK: 'delete-task',
  UPDATE_HISTORY: 'update-history',
  UPDATE_FEED: 'update-feed',
};

// this method will wait 5 secconds to read the entire job from BE
// if it's called many times withing that time frame it wil wait until the last call
// to perform the request to the BE API.
const FIVE_SECONDS = 5 * 1000;
const refreshJobAfterRealTime = debounce((controller, jobId) => {
  controller.readJob(jobId);
}, FIVE_SECONDS);

markAsSideEffect(updateJobRealTime);
export function updateJobRealTime({ action, ...rest }) {
  if (action === RT_JOB_ACTIONS.UPDATE_TASK) {
    const { task: rtTask, taskId } = rest;
    const index = this.state.get('tasks').findIndex(task => task.get('id') === taskId);

    if (index >= 0) {
      this.controller.dispatch([state => state.updateIn(['tasks', index], parseTasksRealTime(rtTask))]);
    }
  } else if (action === RT_JOB_ACTIONS.CREATE_TASK) {
    // get all job tasks, it will be handled by `refreshJobAfterRealTime`
  } else if (action === RT_JOB_ACTIONS.DELETE_TASK) {
    // get all job tasks, it will be handled by `refreshJobAfterRealTime`
  }

  if (action !== RT_JOB_ACTIONS.UPDATE_HISTORY) {
    refreshJobAfterRealTime(this.controller, rest.jobId);
  }
  // else if (action === 'update-history') {} <-- not doing here, this goes on TDV
  // else if (action === 'update-feed') {} <-- not implementing yet
}

function parseTasksRealTime(rtTask) {
  //rtTask > task comming from real time event.
  return task => {
    const assigneeAccount = buildAssigneeAccount(rtTask);

    return task
      .set('assigneeAccount', assigneeAccount)
      .set('startDateConfirmed', rtTask.startDateConfirmed)
      .set('startDate', rtTask.startDate)
      .set('expectedFinishDate', rtTask.endDate)
      .set('durationDays', rtTask.duration)
      .set('predecessors', fromJS(rtTask.predecessors))
      .set('status', rtTask.status)
      .set('overdue', rtTask.overdue)
      .set('lateStartDate', rtTask.lateStartDate)
      .set('lateEndDate', rtTask.lateEndDate)
      .set('missingReference', rtTask.missingReference)
      .set('daysBehind', rtTask.daysBehind)
      .set('userScheduler', fromJS(rtTask.builderUserScheduler))
      .set('userSuper', fromJS(rtTask.builderUserSuper))
      .set('checkedIn', rtTask.checkedIn);
  };
}

function buildAssigneeAccount(task) {
  return fromJS({
    company: task.assigneeAccountName,
    companyId: task.assigneeAccountId,
    companyName: task.assigneeAccountName,
    companyType: task.assigneeAccountType,
    installerId: task.installerId,
    installerName: task.installerName,
    installerStatus: task.installerStatus,
  });
}
