import React from 'react';
import { fromJS, Map } from 'immutable';
import { useAppContext } from 'app/App.context';
import { useController, EMPTY_JOB_FEED } from '@tradetrax/web-common';
import * as actions from './JobDetailsActions';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { useLocation } from 'react-router-dom';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

export const STAGES_VIEW = 'stages';
export const LIST_VIEW = 'list';
export const GRID_VIEW = 'grid';
export const SCHEDULE_VIEW = 'SCHEDULE_VIEW';
export const JOB_STAGES_EXPANDED_STATE = 'JOB_STAGES_EXPANDED_STATE';

const emptyJob = fromJS({
  _id: 'aaaaaabbbbbbccccccdddddd',
  name: '',
  status: '',
  tasks: [],
  communityId: 'aaaaaabbbbbbccccccdddddd',
  statusData: {},
  templateId: 'aaaaaabbbbbbccccccdddddd',
});
export const emptyTask = fromJS({
  name: '',
  duration: 0,
  startDate: '',
  status: '',
  assigneeAccountId: '',
});

export const emptyTaskList = fromJS([]);
export const emptyExpandedState = Map({ stages: Map(), list: Map() });
export const emptyFeedCount = fromJS([{ count: 3 }, { count: 3 }]);

const emptyState = fromJS({
  job: emptyJob,
  tasks: emptyTaskList,
  filteredTasks: emptyTaskList,
  tasksTotalCount: 10,
  feed: EMPTY_JOB_FEED,
  feedCount: emptyFeedCount,
  hasPermission: true,
  scheduleView: STAGES_VIEW,
  attachmentsView: GRID_VIEW,
  expandedRows: emptyExpandedState,
  stages: [],
  taskTypes: emptyTaskList,
  isLoading: true,
});

export const JobDetailsContext = (jobId, defaultTab = 'schedule') => {
  const appContext = useAppContext();
  const filterContext = useFilterContext(FILTER_NAME.JOB);
  const { filterState } = filterContext;
  const feedLoaderRef = React.useRef();
  const schedulerRef = React.useRef();
  const loaderRef = React.useRef();
  const attachmentsRef = React.useRef();
  const initState = emptyState.set('tab', defaultTab).setIn(['job', '_id'], jobId);
  const [state, controller] = useController(actions, initState, {
    ...appContext,
    filterState,
    refreshTask,
    refreshTaskStatus,
    loaderRef,
    feedLoaderRef,
    schedulerRef,
    attachmentsRef,
  });

  //TODO: Remove companies
  const { user, account, companies, assignees } = appContext.appState.toObject();
  const { modal, appState, hasPermission } = appContext;
  const { tab, tasks } = state.toObject();
  const location = useLocation();
  const userId = user.get('_id');
  const filter = filterState.get('values');

  React.useEffect(() => {
    controller.filterTasks(userId);
  }, [controller, tasks, filter, userId]);

  React.useEffect(() => {
    controller.loadAttachmentsView();
    controller.loadScheduleView().then(() => {
      controller.readJob(jobId);
      controller.loadLastStagesExpandedState(jobId);
    });
  }, [controller, jobId]);

  React.useEffect(() => {
    const currentPath = location.pathname;
    const regexUrlTaskDetails = /^\/jobs\/.*\/task\//;
    const isViewTaskDetails = regexUrlTaskDetails.test(currentPath);

    if (!isViewTaskDetails) controller.readJob(jobId);
  }, [controller, jobId, location]);

  React.useEffect(() => {
    if (tab === 'feed') {
      window.location.hash = tab;
      controller.dispatch([state => state.set('feed', EMPTY_JOB_FEED).set('feedCount', emptyFeedCount)]).then(() => {
        feedLoaderRef.current?.resetLoadMoreRowsCache(true);
      });
    }
  }, [tab, controller, feedLoaderRef, jobId]);

  useChannel(CHANNELS.JOB, jobId, controller.updateJobRealTime);

  return {
    state,
    controller,
    filterContext,
    user,
    account,
    companies,
    modal,
    appState,
    feedLoaderRef,
    schedulerRef,
    loaderRef,
    hasPermission,
    appContext,
    attachmentsRef,
    assignees,
  };
};

function refreshTask(index) {
  return updatedTask =>
    this.controller.dispatch([
      state => {
        return state
          .updateIn(['tasks', index], task => (task ? task.merge(fromJS(updatedTask)).update(isTaskReady) : task))
          .updateIn(['job', 'tasks', index], task => task.merge(fromJS(updatedTask)).update(isTaskReady));
      },
    ]);
}

function isTaskReady(task) {
  const isReady =
    !!task.get('durationDays') && !!task.getIn(['assigneeAccount', 'companyId']) && !!task.get('startDate');
  return task.set('isReady', isReady);
}

function refreshTaskStatus(index) {
  return updatedTask => {
    const task = fromJS(updatedTask);
    const status = !!task.get('checkedIn') ? 'checked-in' : task.get('status');
    this.controller.dispatch([
      state => state.updateIn(['tasks', index], t => t.merge(task.set('status', status)).update(isTaskReady)),
    ]);
  };
}
