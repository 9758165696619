import React, { useState } from 'react';
import { Modal, Form, Button, FormControl, Col } from 'react-bootstrap';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton, Typeahead } from '@tradetrax/web-common';
import { Map } from 'immutable';
import { Controller } from 'react-hook-form';

import TaskDependenciesController from './TaskDependenciesController';

export function TaskDependencies({ controller, taskOrStage, tasks: tasksAndStages, template, close }) {
  const tasks = tasksAndStages.filter(task => !task.get('isStageTask'));
  const dependenciesController = TaskDependenciesController({ controller, taskOrStage, tasks, template });
  const { fields, clearErrors } = dependenciesController;
  const onClose = () => {
    clearErrors();
    close();
  };

  const isStage = taskOrStage.get('isStage');
  return (
    <Modal show={true} onHide={onClose} size="lg" id="task-dep">
      <Modal.Header closeButton>
        <Modal.Title>
          Edit Predecessors{<br />}
          <h6 className="font-weight-bold">{`${isStage ? 'Stage' : 'Task'}: ${taskOrStage.get('name')}`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={dependenciesController.onSubmit}>
        <Modal.Body>
          {fields.map((predecessor, index) => {
            predecessor.index = index;
            return (
              <Form.Row key={predecessor.id}>
                <DependencyRow
                  predecessor={predecessor}
                  depController={dependenciesController}
                  tasks={tasks}
                  currentTask={taskOrStage}
                  isFirstRow={index === 0}
                  clearError={clearErrors}
                />
              </Form.Row>
            );
          })}
          <Form.Row>
            <Col sm="11" className="text-center pt-2">
              {fields.length === 0 && <p className="text-secondary mb-4">No predecessors added for this task.</p>}
            </Col>
            <Form.Group as={Col} sm={{ span: 1 }}>
              <Button size="lg" onClick={dependenciesController.addRow}>
                <FontAwesomeIcon icon="plus" />
              </Button>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton variant="secondary" onClick={() => close()}>
            Cancel
          </PrimaryButton>
          <PrimaryButton type="submit">Update</PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const LagTimeInput = styled(FormControl)`
  background: none !important;
  padding-right: 0 !important;
`;

const getRowIndex = (id, tasks) => {
  const predecessorTask = tasks.find(task => id === task.get('id'));
  return predecessorTask ? predecessorTask.get('rowIndex') : '';
};

const DependencyRow = ({ predecessor, depController, tasks, currentTask, isFirstRow = false, clearError }) => {
  const { register, errors, validateLagTime, remove, control } = depController;
  const {
    rowIndex,
    setRowIndex,
    taskValue,
    typeValue,
    taskError,
    typeErrors,
    lagTimeError,
    errorMessage,
  } = RowController({
    tasks,
    predecessor,
    errors,
  });

  const currentTaskId = currentTask.get('id');
  const byKey = React.useMemo(() => tasks.reduce((memo, value) => memo.set(value.get('id'), value), Map()), [tasks]);
  const TASKS = React.useMemo(() => tasks.filter(task => currentTaskId !== task.get('id')).toArray(), [
    tasks,
    currentTaskId,
  ]);

  const onChangeLagTime = event => {
    const lagTime = event.target.value;
    validateLagTime(lagTime, predecessor.index);
  };
  const removeRow = index => {
    clearError(`predecessors[${predecessor.index}].taskId`);
    remove(index);
  };

  return (
    <>
      <Form.Group as={Col} sm={1} className="text-truncate">
        {isFirstRow && <Form.Label>Row #</Form.Label>}
        <Form.Control
          size="lg"
          value={rowIndex}
          readOnly
          style={{ backgroundColor: '#fff', color: '#6C6E7A', cursor: 'default' }}
        />
      </Form.Group>
      <Form.Group as={Col} sm={5}>
        {isFirstRow && <Form.Label>Task</Form.Label>}
        <Controller
          control={control}
          defaultValue={taskValue}
          name={`predecessors[${predecessor.index}].taskId`}
          render={({ onChange, onBlur, value }) => {
            const selected = value ? [byKey.get(value)] : [];
            return (
              <Typeahead
                id={`predecessors[${predecessor.index}].taskId`}
                className={cn('typeahead-dropdown-view', {
                  'is-invalid': taskError,
                  'is-valid': !taskError,
                })}
                filterSelected={x => x}
                placeholder="Choose a task"
                labelKey={option => option?.get('name') || ''}
                onChange={([e]) => {
                  const id = e ? e.get('id') : '';
                  onChange(id);
                  depController.validateTask(id, predecessor.index);
                  setRowIndex(getRowIndex(id, tasks));
                }}
                onBlur={onBlur}
                selected={selected}
                options={TASKS}
                isInvalid={!!taskError}
              />
            );
          }}
        />
        <Form.Control.Feedback type="invalid">
          <FontAwesomeIcon icon="circle-exclamation" className="mr-1" />
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} sm={4}>
        {isFirstRow && <Form.Label>Type</Form.Label>}
        <FormControl
          as="select"
          size="lg"
          isInvalid={typeErrors}
          name={`predecessors[${predecessor.index}].dependencyType`}
          ref={register({ required: true })}
          defaultValue={typeValue}
        >
          <option value="" disabled>
            Select type
          </option>
          <option value="FS">Finish to Start</option>
          <option value="SS">Start to Start</option>
          <option value="FF">Finish to Finish</option>
          <option value="SF">Start to Finish</option>
        </FormControl>
        <Form.Control.Feedback type="invalid">Required Field</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} sm={1}>
        {isFirstRow && <Form.Label>Lag</Form.Label>}
        <LagTimeInput
          size="lg"
          name={`predecessors[${predecessor.index}].lagTime`}
          isInvalid={lagTimeError}
          ref={register()}
          defaultValue={predecessor.lagTime}
          onChange={onChangeLagTime}
        />
        <Form.Control.Feedback type="invalid" className={cn({ 'd-block': !!lagTimeError })}>
          <FontAwesomeIcon icon="circle-exclamation" />
          {` `}
          {lagTimeError && lagTimeError.type === 'min' && <FontAwesomeIcon icon={faArrowDown} />}
          {lagTimeError && lagTimeError.type === 'max' && <FontAwesomeIcon icon={faArrowUp} />}
          {lagTimeError && lagTimeError.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} sm={1}>
        {isFirstRow && <Form.Label>&nbsp;</Form.Label>}
        <Button variant="secondary" size="lg" onClick={() => removeRow(predecessor.index)}>
          <FontAwesomeIcon icon="trash" />
        </Button>
      </Form.Group>
    </>
  );
};

const ERROR_MAP = {
  required: 'Task required',
  validate: 'Circular Dependency',
};

function RowController({ tasks, predecessor, errors }) {
  const [rowIndex, setRowIndex] = useState(getRowIndex(predecessor.taskId, tasks));
  let taskValue = predecessor.taskId;
  taskValue = typeof taskValue === 'number' ? taskValue : '';
  const typeValue = predecessor.dependencyType || 'FS';

  const taskError = errors.predecessors ? errors.predecessors[`${predecessor.index}`]?.taskId : null;
  const typeErrors = errors.predecessors ? errors.predecessors[`${predecessor.index}`]?.dependencyType : null;
  const lagTimeError = errors.predecessors ? errors.predecessors[`${predecessor.index}`]?.lagTime : null;
  const errorMessage = taskError ? ERROR_MAP[taskError.type] : '';
  return {
    rowIndex,
    setRowIndex,
    taskValue,
    typeValue,
    taskError,
    typeErrors,
    lagTimeError,
    errorMessage,
  };
}
