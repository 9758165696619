import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { IconButtonWrap } from '@tradetrax/web-common/lib/Button';
import { LIST_VIEW, STAGES_VIEW } from './JobDetailsContext';
import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { SCHEDULE_VIEW } from './JobDetailsContext';
const ICON_MAP = [
  { icon: 'bars-staggered', view: STAGES_VIEW, label: 'Stages' },
  { icon: 'calendar-days', view: CALENDAR_VIEW, label: 'Calendar' },
  { icon: 'list', view: LIST_VIEW, label: 'List' },
];

export function JobDropDownMenu({ toggleView }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedOption = JSON.parse(localStorage.getItem(SCHEDULE_VIEW));
    return storedOption || ICON_MAP[0];
  });
  const chevronIcon = isOpen ? 'chevron-up' : 'chevron-down';
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = option => {
    toggleView(option);
    setSelectedOption(option);
    setIsOpen(false);
    localStorage.setItem(SCHEDULE_VIEW, JSON.stringify(option));
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef, setIsOpen]);

  return (
    <div ref={dropdownRef} className="position-relative mr-3">
      <IconButtonWrap
        className={cn('btn', { 'active-on-hover': !isOpen, active: isOpen })}
        onClick={toggleDropdown}
        large
        activeWhiteTxtColor
      >
        <FontAwesomeIcon icon={selectedOption.icon} className="mr-2" />
        <FontAwesomeIcon icon={chevronIcon} className="font-size-14" />
      </IconButtonWrap>
      {isOpen && (
        <DropdownContainer>
          {ICON_MAP.map((item, key) => {
            return (
              <DropDownOptionContainer
                key={key}
                icon={item.icon}
                label={item.label}
                onClick={() => handleOptionClick(item)}
                selectedOption={selectedOption}
              />
            );
          })}
        </DropdownContainer>
      )}
    </div>
  );
}

const DropDownOptionContainer = ({ icon, label, onClick, selectedOption }) => {
  return (
    <DropDownOption onClick={onClick}>
      <FontAwesomeIcon className="mr-2 font-size-14" icon={icon} />
      <span className="font-size-16">{label}</span>
      {selectedOption.label === label && <FontAwesomeIcon className="ml-2 checkIcon font-size-14" icon="check" />}
    </DropDownOption>
  );
};

const DropdownContainer = styled.div`
  background-color: ${colors.white};
  border: 2px solid ${colors.gray};
  border-radius: 10px;
  position: absolute;
  top: 42px;
  left: -60px;
  z-index: 2;
`;

const DropDownOption = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray100};
  cursor: pointer;
  color: ${colors.gray400};
  padding: 10px 0 10px 15px;
  width: 138px;

  &:hover {
    color: ${colors.blue300};
    background-color: ${colors.blue100};
  }

  .checkIcon {
    color: ${colors.blue300};
  }
`;
