import React from 'react';
import styled from 'styled-components';
import navigate from 'app/navigate';
import { Link } from 'react-router-dom';
import { colors, ChipStatusDropdown, STATUS_MAP } from '@tradetrax/web-common';
import { NOT_STARTED, IN_PROGRESS, COMPLETED, getUserDomain } from '@tradetrax/web-common/lib/utils';
import { CTR } from './CTR';
import { Confirm } from './Confirm';
import { PopoverApprovalStatus } from '../Popover/PopoverApprovalStatus';
import { CriticalPathLabel } from '@tradetrax/web-common/lib/CriticalPath';

const StyledLinkToDo = styled(Link)`
  text-decoration: none;
  color: ${colors.gray800};
  &:hover {
    text-decoration: underline;
    color: ${colors.blue300};
  }
`;

const StyledLinkToJob = styled(Link)`
  color: ${colors.black};
  &:hover {
    color: ${colors.blue400};
  }
`;

export const ToDoCard = ({
  task,
  controller,
  kind,
  tdvLink,
  canEditTaskStatus,
  openConfirmTask,
  canSendUpdateRequest,
  user,
}) => {
  const { isBuilder } = getUserDomain(user);
  const status = task.get('status');
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const linkTo = tdvLink(taskId, jobId, status);
  const linkRef = React.useRef();
  const showConfirmationStatus = kind === 'confirmationRequests' || kind === 'updateToday';
  const onChangeStatus = status => controller.changeStatus(task, kind, status);
  const isCritical = task.get('isCritical');
  const lotNumber = task.getIn(['job', 'lotNumber']);
  const truncatedLotNumber = lotNumber?.length > 10 ? lotNumber.slice(0, 10) + '...' : lotNumber;
  const createdBySub = task.getIn(['changeRequestOnReviewByBuilder', 'createdByType']) === 'sub';

  return (
    <Card status={status} data-testid="todo-card">
      <div className="d-flex flex-row">
        <div className="d-flex flex-column mr-auto" style={{ maxWidth: '160px' }}>
          <StyledLinkToDo to={linkTo} ref={linkRef} className="mb-2 font-weight-bold">
            <TaskNameLabel>{task.get('name')}</TaskNameLabel>
          </StyledLinkToDo>
          <div className="d-flex" style={{ maxWidth: '180px' }}>
            {
              <ShowJobNameOrLotNumber
                isBuilder={isBuilder}
                jobId={jobId}
                task={task}
                lotNumber={lotNumber}
                truncatedLotNumber={truncatedLotNumber}
              />
            }
          </div>
          {isCritical && (
            <CriticalPathContainer>
              <CriticalPathLabel />
            </CriticalPathContainer>
          )}
        </div>
        <CTR controller={controller} task={task} />
      </div>
      <div className="d-flex flex-row justify-content-between">
        <span className="font-size-14">{task.get('dayLabel')}</span>
        <ChipStatusDropdown
          {...STATUS_MAP[status]}
          notStarted={status !== NOT_STARTED}
          progress={status !== IN_PROGRESS}
          completed={status !== COMPLETED}
          onSelect={onChangeStatus}
          disabled={!canEditTaskStatus(task)}
        />
      </div>
      {showConfirmationStatus && createdBySub && <PopoverApprovalStatus task={task} />}
      <Confirm
        openConfirmTask={openConfirmTask}
        controller={controller}
        task={task}
        canSendUpdateRequest={canSendUpdateRequest}
      />
    </Card>
  );
};

const STATUS_COLOR = {
  'not-started': colors.gray100,
  'in-progress': colors.green300,
  completed: colors.green300,
  'not-ready-to-start': colors.red,
};

const CriticalPathContainer = styled.div`
  position: relative;
  top: -9px;
`;

export const Card = styled.div`
  ${props => `
    background: ${colors.white};
    border: 1px solid ${colors.gray100};
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    padding: 1rem 1rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 130px;

    &::before {
      position: absolute;
      background-color: ${STATUS_COLOR[props.status]};
      left: 0;
      top: 0;
      bottom: 0;
      width: 8px;
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      content:'';
    }
`}
`;

const TaskNameLabel = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ShowJobNameOrLotNumber = ({ isBuilder, jobId, task, lotNumber, truncatedLotNumber }) => {
  if (isBuilder) {
    return (
      <StyledLinkToJob className="mb-2 text-truncate text-nowrap" to={navigate.to.JobDetails({ jobId }, false)}>
        {task.getIn(['job', 'name'])}
      </StyledLinkToJob>
    );
  }

  return lotNumber ? (
    <TaskNameLabel className="mb-2 text-gray-400">{`LOT #${truncatedLotNumber}`}</TaskNameLabel>
  ) : (
    <StyledLinkToJob className="mb-2 text-truncate text-nowrap" to={navigate.to.JobDetails({ jobId }, false)}>
      {task.getIn(['job', 'name'])}
    </StyledLinkToJob>
  );
};
