import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useController, markAsSideEffect } from '@tradetrax/web-common';

const actions = {
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onMouseUpTable,
};

const initialState = {
  draggable: null,
  task: null,
  table: null,
};

function debounceExpandStage(gridController) {
  let timeout;

  executedFunction.cancel = () => clearTimeout(timeout);

  return executedFunction;
  function executedFunction(rowIndex, stageId) {
    const later = () => {
      clearTimeout(timeout);
      gridController.toggleRowDnD(rowIndex, stageId);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, 1000);
  }
}

export const DragNDropController = (tableRef, updateTaskOrder, gridController, viewModel) => {
  const expandStage = React.useMemo(() => debounceExpandStage(gridController), [gridController]);
  const layoutContent = global.document.getElementById('layout-content');
  const tableContainer = global.document.getElementById('table-container');
  const [state, controller] = useController(actions, initialState, {
    tableRef,
    updateTaskOrder,
    expandStage,
    viewModel,
    layoutContent,
    tableContainer,
  });

  return { state, controller };
};

async function onMouseDown(e, task) {
  e.preventDefault();

  const draggable = global.document.createElement('div');
  draggable.style.position = 'absolute';
  draggable.style.zIndex = 99999;
  draggable.style.cursor = 'move';
  draggable.style.pointerEvents = 'none';
  draggable.className = 'd-inline';
  global.document.body.appendChild(draggable);
  ReactDOM.render(<DraggedTask task={task} />, draggable);

  const table = document.querySelector('.ReactVirtualized__Table__Grid');
  table.addEventListener('mouseup', this.controller.onMouseUpTable);
  global.document.addEventListener('mousemove', this.controller.onMouseMove);
  global.document.addEventListener('mouseup', this.controller.onMouseUp);
  moveAt(draggable, e);
  if (this.tableContainer) this.tableContainer.style.marginBottom = '20px';

  return state => ({ ...state, task, draggable, table });
}

markAsSideEffect(onMouseUpTable);
function onMouseUpTable(e) {
  const row = getRow(e.target);
  this.expandStage.cancel();

  if (!row) return;

  const isStage = row.classList.contains('is-stage');
  const stageId = row.getAttribute('data-stage-id') || null;
  const { task } = this.state;
  const endOrder = isStage ? -1 : parseInt(row.getAttribute('data-task-order'), 10);
  const orderAboveStages = isAboveStages();

  // droping on the same row? no need to re order.
  if (task.get('order') === endOrder) return;

  this.updateTaskOrder({
    task,
    endOrder,
    isBefore: isInsertBefore,
    stageId,
    orderAboveStages,
  });

  function isAboveStages() {
    if (stageId) return false;
    const rows = Array.from(row.parentElement.children);
    const rowIndex = rows.indexOf(row);
    const firstStageIndex = rows.findIndex(r => r.getAttribute('data-stage-id'));
    return rowIndex < firstStageIndex;
  }
}

let waiting = false; // used for throttling.
let overRow = null;
let isInsertBefore = null;
markAsSideEffect(onMouseMove);
function onMouseMove(e) {
  moveAt(this.state.draggable, e);
  if (waiting) return;

  const { table, task } = this.state;
  const height = table.offsetHeight;
  const top = getOffsetTop(table);
  let dy = null;
  const row = getRow(e.target);

  if (!row) return this.expandStage.cancel();

  const isStage = row.classList.contains('is-stage');
  const stageId = row.getAttribute('data-stage-id');
  const isChildren = !isStage && !!stageId;
  const offsetStage = isChildren ? row.parentElement.parentElement.offsetTop : 0;
  const elementTop = top + row.offsetTop - table.scrollTop + offsetStage;
  const isBefore = elementTop + 40 > e.pageY;

  if (row !== overRow || isInsertBefore !== isBefore) {
    removeBorder();
    this.expandStage.cancel();

    overRow = row;
    isInsertBefore = isBefore;
    row.classList.add(`push-${isBefore ? 'up' : 'down'}`);

    if (isStage && row.classList.contains('is-collapsed')) {
      const rowIndex = parseInt(row.ariaRowIndex || row.getAttribute('aria-rowindex'), 10);
      this.expandStage(rowIndex - 1, row.getAttribute('data-stage-id'));
    }

    //isMovingKeyTask
    if (task.get('isKey') === true && task.get('stageId') !== stageId) {
      this.state.draggable.firstElementChild.classList.add('bg-danger');
      this.state.draggable.firstElementChild.classList.remove('bg-dark');
    } else {
      this.state.draggable.firstElementChild.classList.remove('bg-danger');
      this.state.draggable.firstElementChild.classList.add('bg-dark');
    }
  }

  if (top + height - e.pageY <= 40) dy = 0;
  if (e.pageY - top <= 40) dy = -2;
  if (dy !== null) {
    waiting = true;
    const stageRow = getStageRow(row);
    const rowIndex = parseInt(stageRow.ariaRowIndex || stageRow.getAttribute('aria-rowindex'), 10);
    this.tableRef.current.scrollToRow(Math.max(rowIndex + dy, 0));
    setTimeout(function() {
      waiting = false;
    }, 350);
  }
}

function getStageRow(row) {
  if (row.classList.contains('ReactVirtualized__Table__row')) return row;
  return getStageRow(row.parentElement);
}

function getRow(element) {
  if (!element) return null;
  return element.hasAttribute('data-task-order') ? element : getRow(element.parentElement);
}

function removeBorder() {
  if (overRow) {
    overRow.classList.remove('push-up');
    overRow.classList.remove('push-down');
    overRow.classList.remove('push-up-edge');
    overRow.classList.remove('push-down-edge');
  }
}
function getOffsetTop(el, sum = 0) {
  if (!el) return sum;
  return getOffsetTop(el.offsetParent, sum + el.offsetTop);
}

async function onMouseUp(e) {
  this.state.table.removeEventListener('mouseup', this.controller.onMouseUpTable);
  global.document.removeEventListener('mousemove', this.controller.onMouseMove);
  global.document.removeEventListener('mouseup', this.controller.onMouseUp);
  removeBorder();

  if (!e.target.hasAttribute('data-task-order')) {
    const { task } = this.state;
    const droppedOnBottom = e.target.classList.contains('ReactVirtualized__Grid');
    const droppedOnLayout = isDroppedOnContainer(e.target, this.layoutContent);

    if (droppedOnBottom) {
      this.updateTaskOrder({ task, isOnBottomEdge: true });
    } else if (droppedOnLayout) {
      const tableTop = getOffsetTop(this.tableContainer);
      if (e.pageY >= tableTop + this.tableContainer.clientHeight) {
        this.updateTaskOrder({ task, isOnBottomEdge: true });
      } else {
        this.updateTaskOrder({ task, isOnTopEdge: true });
      }
    }
  }

  this.tableContainer.style.marginBottom = '';

  return ({ draggable }) => {
    ReactDOM.unmountComponentAtNode(draggable);
    global.document.body.removeChild(draggable);
    return { draggable, task: null };
  };
}

function isDroppedOnContainer(element, container) {
  if (!element) return false;
  if (element === container) return true;

  if (element.classList.contains('ReactVirtualized__Grid')) return false;
  return isDroppedOnContainer(element.parentElement, container);
}

function moveAt(draggable, { pageX, pageY }) {
  if (draggable) {
    draggable.style.left = pageX - 10 + 'px';
    draggable.style.top = pageY - draggable.offsetHeight / 2 + 'px';
  }
}

const DraggedTask = ({ task }) => {
  const isStage = task.get('isStage');

  return (
    <span className="text-white bg-dark p-3">
      <FontAwesomeIcon icon="grip-lines-vertical" className="mr-2" />
      {task.get(isStage ? 'stageIndex' : 'rowIndex')} - {task.get('name')}
      {task.get('isKey') ? <FontAwesomeIcon icon="flag" className="text-yellow-100 ml-2" /> : null}
    </span>
  );
};
