import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';
import { MyTasks as MyTasksSettings } from '@tradetrax/web-common/lib/Profile/Notifications/MyTasks';
import { ReceiveUpdates } from '../../../../Users/MyProfile/Notifications/ReceiveUpdates';
import { emptyCustomRole } from '../CustomRoleDetails.context';

export function MyTasks({ customRole, controller }) {
  const isActive = customRole.get('active');
  const events = customRole.getIn(['settings', 'notifications']);
  const channels = events.get('notificationsChannels');
  const pushChecked = channels && channels.indexOf('push') >= 0;
  const emailChecked = channels && channels.indexOf('email') >= 0;
  const pushDisabled = !isActive || !pushChecked;
  const emailDisabled = !isActive || !emailChecked;

  const update = onlyChannels => controller.updateNotificationEventsSettings(onlyChannels, customRole);
  const onChange = (event, value) => controller.updateNotificationEvents(customRole, event, value);

  const settingsProps = { update, pushChecked, emailChecked, disabled: !isActive };
  const receiveUpdatesProps = { onChange, events, pushDisabled, emailDisabled };

  if (customRole === emptyCustomRole) return null;

  return (
    <Wrapper className={cn('w-75 mb-5', { disabled: !isActive })}>
      <MyTasksSettings {...settingsProps} />
      <ReceiveUpdates {...receiveUpdatesProps} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  &.disabled {
    h5,
    span,
    .btn.btn-link,
    .custom-control.custom-switch input {
      color: ${colors.gray200} !important;
      pointer-events: none;
      user-select: none;
    }
    .custom-control-input[disabled] ~ .custom-control-label::before,
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: ${colors.white};
    }
  }
  li:first-of-type {
    border-top: none;
  }
`;
