import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import navigate from 'app/navigate';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STATUS_MAP, ChipStatusDropdown, EMPTY_ROW, renderCell, OnTime } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import {
  mongoToTrx,
  isTaskOverdue,
  getAssigneeBadgeClassName,
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  handleTableScrollStickyColumn2,
} from '@tradetrax/web-common/lib/utils';
import { COL_WIDTHS, WIDTHS, linkToTask } from './shared';
import { MINIMUM_BATCH_SIZE } from './TasksPageContext';

export const OpenTasksTable = ({ state, controller, account, loaderRef, filterContext }) => {
  const scrollDivRef = useRef(null);
  const { tasks, totalCount, maxCount } = state.get('open').toObject();
  const rowClassName = rowData => (!rowData || rowData === EMPTY_ROW ? 'loading' : '');
  const { isFiltering } = filterContext;
  const title = isFiltering ? 'Not Matches Found' : 'No Open Tasks';
  const body = isFiltering
    ? "When there's a Task that meets the filter, it will appear here."
    : 'When a task is not started or still in progress it will appear here.';
  const showDaysBehind = false;

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);

  useEffect(() => {
    handleTableScrollStickyColumn2({ target: scrollDivRef.current }, scrollDivRef);
  }, [tasks]);

  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={controller.loadMoreOpenTasks}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        ref={loaderRef}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div ref={scrollDivRef} style={{ flex: '1 1 auto', overflowX: 'auto' }}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1200 ? width : 1200;

                return (
                  <Table
                    className="trx-table stickyColumn2"
                    estimatedRowSize={80}
                    headerHeight={40}
                    height={height}
                    onRowClick={() => {}}
                    onRowsRendered={onRowsRendered}
                    overscanRowCount={0}
                    ref={registerChild}
                    rowCount={Math.min(maxCount, totalCount)}
                    rowClassName={({ index }) => rowClassName(tasks.get(index))}
                    rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                    rowHeight={80}
                    width={minWidth}
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top="250px">
                        <EmptyState icon="wrench" title={title} body={body} data-testid="open-tasks-empty-state" />
                      </EmptyStateCenterContainer>
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                    <Column
                      label="Task Name"
                      dataKey="name"
                      minWidth={250}
                      width={minWidth - WIDTHS}
                      cellRenderer={renderCell(({ cellData: taskName, rowData: task, rowIndex }) => (
                        <Link className="main-link" to={linkToTask(task, 'open')}>
                          <div className="text-truncate">{taskName}</div>
                        </Link>
                      ))}
                    />
                    <Column
                      label="Job"
                      dataKey="job"
                      width={COL_WIDTHS.jobName}
                      cellRenderer={renderCell(({ cellData }) => {
                        const name = cellData.get('name');
                        const lotNumber = cellData.get('lotNumber');
                        const jobId = cellData ? cellData.get('id') : 'aaaabbbbccccdddd';
                        return (
                          <>
                            <Link className="secondary-link" to={navigate.to.JobDetails({ jobId }, false)}>
                              <div className="text-truncate">{name}</div>
                            </Link>
                            {lotNumber && (
                              <span className="d-block text-muted text-truncate">{`LOT #${lotNumber}`}</span>
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Account Assignee"
                      dataKey="assigneeAccount"
                      width={COL_WIDTHS.assignee}
                      cellRenderer={renderCell(({ cellData: assigneeAccount }) => (
                        <span
                          style={{ maxWidth: COL_WIDTHS.assignee }}
                          className={`text-truncate not-permission ${getAssigneeBadgeClassName(
                            assigneeAccount.get('companyId'),
                            account.get('_id')
                          )}`}
                        >
                          {assigneeAccount.get('company') || 'Needs Assignment'}
                        </span>
                      ))}
                    />
                    <Column
                      label="Confirmed"
                      dataKey="startDateConfirmed"
                      width={COL_WIDTHS.duration}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => (
                        <div
                          className={cn('text-right mr-3', {
                            'text-success': !!cellData,
                            'text-gray-200': !cellData,
                          })}
                        >
                          <FontAwesomeIcon icon="check" />
                        </div>
                      ))}
                    />
                    <Column
                      label="Exp. Start"
                      dataKey="startDate"
                      width={COL_WIDTHS.expStart}
                      cellRenderer={renderCell(({ rowData: task, cellData: startDate }) => {
                        const { isOverdueStart } = isTaskOverdue(task);
                        return (
                          <>
                            <span className={cn('with-sublabel', { 'text-danger': !!isOverdueStart })}>
                              {mongoToTrx(startDate) || 'MM/DD/YY'}
                              {!!isOverdueStart && <span>Missed Start</span>}
                            </span>
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Exp. Finish"
                      dataKey="expectedFinishDate"
                      width={COL_WIDTHS.expFinish}
                      cellRenderer={renderCell(({ rowData: task, cellData: finishDate }) => {
                        const { isOverdueFinish } = isTaskOverdue(task);
                        return (
                          <>
                            <span className={cn('with-sublabel', { 'text-danger': !!isOverdueFinish })}>
                              {mongoToTrx(finishDate) || 'MM/DD/YY'}
                              {!!isOverdueFinish && <span>Missed Finish</span>}
                            </span>
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Duration"
                      dataKey="durationDays"
                      width={COL_WIDTHS.duration}
                      cellRenderer={renderCell(({ cellData }) => `${cellData}d`)}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ rowData, cellData }) => (
                        <div className="d-flex flex-column align-items-center">
                          <ChipStatusDropdown
                            {...STATUS_MAP[cellData]}
                            notStarted={cellData !== NOT_STARTED}
                            progress={cellData !== IN_PROGRESS}
                            completed={cellData !== COMPLETED}
                            onSelect={status => controller.changeStatus(rowData, 'open', status)}
                          />
                          {showDaysBehind && <OnTime onTime={rowData.get('daysBehind')} hideOnSchedule />}
                        </div>
                      ))}
                    />
                    {/* <Column
                    label=""
                    dataKey=""
                    width={COL_WIDTHS.action}
                    cellRenderer={({ rowData }) => <TableRowDropDown removeTask renameTask duplicateTask />}
                  /> */}
                    <Column label="" dataKey="" width={COL_WIDTHS.action} />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
