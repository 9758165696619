import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChipStatus, STATUS_MAP } from '../../ChipStatus';
import { colors } from '../../theme';
import { AssigneeSelect } from '../../AssigneeSelect';
import { Preceding } from './Preceding';
import { UpdateRequestStatus } from './UpdateRequestStatus';

export const UpcomingCardStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  background-color: ${colors.pearl};
  border: solid 1px ${colors.gray};
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  margin-bottom: 12px;
  min-height: 80px;
  padding: 12px 21px;

  @media (min-width: 1200px) {
    padding-right: 0px;
  }
  @media (min-width: 1400px) {
    padding-right: 0;
  }
  &:hover {
    text-decoration: none;
  }
  span {
    color: ${colors.gray800};
    text-decoration: none;
  }

  .span-check {
    width: 30px;
    margin-right: 8px;
  }

  .span-date {
    max-width: 163px;
  }

  .span-status {
    width: 160px;
  }
`;

const StyledLinkUpcoming = styled(Link)`
  text-decoration: none;
  color: ${colors.gray800};
  margin-bottom: 5px;
  &:hover {
    text-decoration: underline;
    color: ${colors.blue300};
  }
`;

const UpcomingCardContainer = ({
  task,
  controller,
  isTrade,
  linkToTaskDetails,
  linkToJob,
  assigneeProps,
  linkCommunityDetails,
}) => {
  const taskId = task.get('id');
  const job = task.get('job');
  const jobId = job.get('id');
  const isStartDateConfirmed = !!task.get('startDateConfirmed');
  const lotNumber = task.getIn(['job', 'lotNumber']);
  const communityName = task.get('communityName');
  const communityId = task.get('communityId');
  const builderName = task.getIn(['job', 'builderName']);
  const truncatedLotNumber = lotNumber?.length > 10 ? lotNumber.slice(0, 10) + '...' : lotNumber;
  const createdBySub = task.getIn(['changeRequestOnReviewByBuilder', 'createdByType']) === 'sub';

  return (
    <div className="position-relative" data-testid="upcoming-card">
      <UpcomingCardStyled>
        <LeftContainer>
          <div className="taskNameContainer">
            <StyledLinkUpcoming
              data-testid="task-name"
              className="font-weight-bold taskName"
              to={linkToTaskDetails(jobId, taskId)}
            >
              {task.get('name')}
            </StyledLinkUpcoming>
            <div className="d-flex" style={{ maxWidth: '255px' }}>
              <StyledLinkUpcoming to={linkToJob(jobId)} className="text-nowrap text-truncate">
                {lotNumber ? `LOT #${truncatedLotNumber}` : job.get('name')}
              </StyledLinkUpcoming>
            </div>

            {isTrade && (
              <CommunityContainer className="text-gray-800 font-size-14 d-flex text-nowrap  mb-1">
                <CommunityLink to={linkCommunityDetails(communityId)} className="community_name">
                  {communityName}
                </CommunityLink>
                <span className="mx-1 text-gray-400">{` by `}</span>
                <span className="community_builderName">{builderName}</span>
              </CommunityContainer>
            )}
          </div>
          <div className="d-flex flex-row assigneeContainer">
            <span className={cn({ 'd-none': !isTrade })}>
              <AssigneeSelect {...assigneeProps} />
            </span>
          </div>
        </LeftContainer>
        <RightContainer>
          {isTrade && <Preceding task={task} showPrecedingTasks={controller.showPrecedingTasks} />}
          <StatusContainer className="d-flex flex-column align-items-end " isTrade>
            <div className="d-flex flex-row mb-2">
              {isTrade && createdBySub && <UpdateRequestStatus task={task} />}
              <span className="span-check">
                <FontAwesomeIcon
                  icon="check"
                  className={cn('mx-2', {
                    'text-success': isStartDateConfirmed,
                    'text-gray-200': !isStartDateConfirmed,
                  })}
                />
              </span>
              <span className="span-date font-size-14 mr-2">{task.get('expectedStartLabel')}</span>
            </div>
            <ChipStatus {...STATUS_MAP[task.get('status')]} />
          </StatusContainer>
        </RightContainer>
      </UpcomingCardStyled>
    </div>
  );
};

const DAYS = ['Today', 'Tomorrow'];

export const UpcomingCard = ({
  tasks,
  date,
  controller,
  isTrade,
  linkToTaskDetails,
  linkToJob,
  assigneeProps,
  linkCommunityDetails,
}) => {
  const [day, dayText] = React.useMemo(() => {
    const mDate = moment(date, 'YYYY-MM-DD');
    const diffDays = mDate.diff(moment().startOf('date'), 'days');
    return [mDate.format('MMM D'), DAYS[diffDays]];
  }, [date]);

  return (
    <div className="mb-4">
      <div className="mb-3 pb-1" style={{ color: `${colors.gray400}` }}>
        <span className="h7 small font-weight-bold">
          {day} {dayText && <span className="ml-2">{dayText}</span>}{' '}
        </span>
      </div>
      {tasks.map(task => (
        <UpcomingCardContainer
          key={task.get('key')}
          task={task}
          controller={controller}
          isTrade={isTrade}
          linkToTaskDetails={linkToTaskDetails}
          linkToJob={linkToJob}
          assigneeProps={assigneeProps(task)}
          linkCommunityDetails={linkCommunityDetails}
        />
      ))}
    </div>
  );
};

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;

  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;

    .assigneeContainer {
      margin-left: 15px;
    }
  }

  @media (min-width: 1400px) {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    .assigneeContainer {
      margin-left: 0;
    }
  }

  @media (min-width: 1700px) {
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    max-width: calc(100% - 330px);
  }

  .taskNameContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    @media (min-width: 1200px) {
      max-width: 280px;
    }

    .taskName {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;

  @media (min-width: 1200px) {
    flex-direction: row-reverse;
    align-items: center;
  }

  @media (min-width: 1400px) {
    flex-direction: column;
  }
  @media (min-width: 1700px) {
    flex-direction: row-reverse;
    min-width: 330px;
  }
`;

const StatusContainer = styled.div`
  margin-right: 0;
  @media (min-width: 1200px) {
    margin-right: 10px;
  }
  @media (min-width: 1700px) {
    margin-right: 20px;
  }
`;

const CommunityLink = styled(Link)`
  text-decoration: none;
  color: ${colors.gray800};
  &:hover {
    text-decoration: underline;
    color: ${colors.blue300} !important;
  }
`;

const CommunityContainer = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 7px;

  .community_builderName,
  .community_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .community_builderName {
    max-width: 60%;
  }
`;
