import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import navigate from 'app/navigate';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChipStatus, colors, STATUS_MAP } from '@tradetrax/web-common';
import { CriticalPathIcon } from '@tradetrax/web-common/lib/CriticalPath';
import { useBulkContext } from '@tradetrax/web-common/lib/BulkActions/BulkActions.context';

export const UpcomingCard = ({ task, isManageTasks, isDisabled = false }) => {
  const taskId = task.get('id');
  const job = task.get('job');
  const jobId = job.get('id');
  const isStartDateConfirmed = !!task.get('startDateConfirmed');
  const isCritical = task.get('isCritical');
  const lotNumber = task.getIn(['job', 'lotNumber']);
  const truncatedLotNumber = lotNumber?.length > 10 ? `${lotNumber.slice(0, 10)}...` : lotNumber;

  const context = useBulkContext();
  const bulkController = isManageTasks && context.controller;
  const selectedTasks = isManageTasks && context.state.get('selectedTasks');
  const isChecked = React.useMemo(() => {
    if (selectedTasks) return !!selectedTasks.find(item => item.get('key') === task.get('key'));
    else return false;
  }, [selectedTasks, task]);

  return (
    <div className="d-flex">
      {isManageTasks && (
        <input
          type="checkbox"
          className="mr-3"
          disabled={isDisabled}
          checked={isChecked}
          onChange={({ target }) => bulkController.toggleCheckRequest(task, target.checked)}
        />
      )}
      <Card data-testid="upcoming-card">
        <div className=" mr-auto d-flex flex-column">
          <div className="d-flex ">
            <Link
              data-testid="task-name"
              className="font-weight-bold text-gray-800"
              to={navigate.to.JobTaskDetails({ jobId, taskId }, false)}
            >
              {task.get('name')}
            </Link>
            {isCritical && <CriticalPathIcon />}
          </div>
          <div>
            <Link className="mr-5 mb-0 text-gray-800 " to={navigate.to.JobDetails({ jobId }, false)}>
              {lotNumber ? (
                <span className="truncateLink text-truncate ">{`LOT #${truncatedLotNumber}`}</span>
              ) : (
                job.get('name')
              )}
            </Link>
          </div>
        </div>

        <RightItemsContainer>
          <div>
            <span className="span-check">
              <FontAwesomeIcon
                icon="check"
                className={cn('', {
                  'text-success': isStartDateConfirmed,
                  'text-gray-200': !isStartDateConfirmed,
                })}
              />
            </span>
            <span className="span-date font-size-14 text-nowrap">{task.get('expectedStartLabel')}</span>
          </div>
          <span className="span-status ml-5 mr-3 pr-1">
            <ChipStatus {...STATUS_MAP[task.get('status')]} />
          </span>
        </RightItemsContainer>
      </Card>
    </div>
  );
};

const RightItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: 920px) {
    flex-direction: row;
    & > div {
      margin-right: 2.5em;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.pearl};
  border: solid 1px ${colors.gray};
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  margin-bottom: 12px;
  min-height: 80px;
  padding-left: 20px;
  flex: 1;
  max-width: 100%;

  a,
  .truncateLink {
    text-decoration: none;

    &:hover {
      color: ${colors.blue300};
      text-decoration: underline;
    }
  }

  .span-check {
    margin-right: 8px;
  }

  .span-date {
    max-width: 163px;
  }

  .span-status {
    width: 160px;
  }
`;
