import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DraggableArea = ({ task, controller, rowIndex, className = '' }) => {
  const isStage = task.get('isStage');

  return (
    <span className={className} onMouseDown={e => !isStage && controller.onMouseDown(e, task)}>
      {isStage ? null : <FontAwesomeIcon icon="grip-lines-vertical" />}
      {rowIndex ? rowIndex : task.get(isStage ? 'stageIndex' : 'rowIndex')}
    </span>
  );
};
