import React from 'react';
import { Wrapper } from './Filter.popup.wrapper';

export const FilterPopup = ({ setVisible, children, top, floatToRight = false, className = '' }) => {
  const wrapperRef = React.useRef();

  React.useEffect(() => {
    const isInsideFilter = node => {
      const wrapper = wrapperRef.current;
      if (!node) return false;
      if (wrapper === node) return true;
      if (node.classList.contains('do-not-close-popup')) return true;
      if (node.classList.contains('rbt-close')) return true;
      if (node.classList.contains('rbt-menu')) return true;
      if (node.classList.contains('rbt-menu-pagination-option')) return true;
      if (node.classList.contains('modal')) return true;
      return isInsideFilter(node.parentElement);
    };

    const onClick = e => {
      if (isInsideFilter(e.target)) return;
      e.preventDefault();
      e.stopPropagation();
      setVisible(false);
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, []);

  return (
    <Wrapper className={className} top={top} floatToRight={floatToRight} ref={wrapperRef}>
      {children}
    </Wrapper>
  );
};
