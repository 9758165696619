import moment from 'moment';
import { List } from 'immutable';
import { buildersService } from 'services';
import { markAsSync, markAsSideEffect } from '../useController';
import { ChangeDateUpcomingModal } from './Modals/Upcoming/ChangeDateUpcomingModal';
import { formatISO } from '../utils';

markAsSync(toggleCheckRequest);
export function toggleCheckRequest(state, task, checked) {
  return state.update('selectedTasks', selectedTasks => {
    if (checked) return selectedTasks.push(task);
    return selectedTasks.filter(selected => selected.get('key') !== task.get('key'));
  });
}

markAsSideEffect(changeDate);
export async function changeDate() {
  const tasksList = this.state.get('selectedTasks');
  const { isAccept, date } = await this.modal.open(ChangeDateUpcomingModal, { tasksList });

  if (!isAccept) return;

  const body = {
    startDate: formatISO(date),
  };
  const tasks = tasksList
    .map(task => {
      return { taskId: task.get('id'), jobId: task.getIn(['job', 'id']) };
    })
    .toJS();

  buildersService
    .bulkUpdateTasks({ body, tasks })
    .then(() => {
      this.parentContext.controller.loadUpcomingTasks();
      this.alert.success({
        message: 'Tasks successfully updated.',
      });
      this.controller.dispatch([
        state =>
          state.update('selectedTasks', tasks => {
            if (isUpcomingDate(date)) {
              const dateObject = new Date(date);
              const formattedDate = moment.utc(dateObject).format('YYYY-MM-DDT00:00:00.000[Z]');
              return tasks.map(task => task.set('startDate', formattedDate));
            } else {
              return tasks.filter(() => false);
            }
          }),
      ]);
    })
    .catch(() => {
      this.alert.error({
        message: 'There was a problem updating this Task. Please try again.',
      });
    });
}

markAsSync(clearSelectedTasks);
export function clearSelectedTasks(state) {
  return state.set('selectedTasks', List());
}

const isUpcomingDate = date => {
  const targetDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const thirtyDaysLater = new Date();
  thirtyDaysLater.setDate(today.getDate() + 30);
  thirtyDaysLater.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  return targetDate >= today && targetDate <= thirtyDaysLater;
};
