import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, TableRowDropDown } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { UL } from './CustomRoles.list';
import navigate from 'app/navigate';
import { CustomRolesContext } from './CustomRoles.context';
import { Header } from './CustomRoles.header';
import { Wrapper } from './CustomRoles.wrapper';
import { LoadingPlaceholder } from './CustomRoles.loading';
import { fromJS } from 'immutable';
import { byDescOrder } from './CustomRoles.common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';

export function CustomRoles() {
  const { state, controller, selectedRole } = CustomRolesContext();
  const isLoading = state.getIn(['customRoles', 'isLoading']);
  const customRoles = state.get('customRoles');
  const rolesSize = customRoles.size;
  const [active, inactive] = React.useMemo(() => {
    if (isLoading) return [fromJS([]), fromJS([])];
    const active = customRoles.filter(role => role.get('active')).sort(byDescOrder);
    const inactive = customRoles.filter(role => !role.get('active')).sort(byDescOrder);
    return [active, inactive];
  }, [customRoles, isLoading]);
  const activeSize = active.size;
  const inactiveSize = inactive.size;
  const isEmpty = !isLoading && rolesSize === 0;

  const onToggleRole = cr => (_, e) => {
    e.stopPropagation();
    controller.toggleRoleActive(cr);
  };

  if (isEmpty)
    return (
      <>
        <Header controller={controller} />
        <span className="ml-4 pt-3 mb-5 font-weight-bold">0 Custom Roles</span>
        <EmptyStateCenterContainer>
          <EmptyState
            icon="gear"
            title="No Custom Roles Created"
            body="When you create a Custom Role, it will appear here."
          />
        </EmptyStateCenterContainer>
      </>
    );

  return (
    <>
      <Header controller={controller} />
      <Wrapper>
        <div className="list-area">
          <span className="pt-3 pb-3 font-weight-bold">{`${activeSize} Custom ${plural(activeSize, 'Role')}`}</span>
          <UL>
            <LoadingPlaceholder visible={isLoading} />
            <ActiveRoles roles={active} onToggleRole={onToggleRole} selectedRole={selectedRole} />
            <div className={cn('mt-3 ml-4 pl-2 pb-3 font-weight-bold', { 'd-none': !inactiveSize })}>
              {`${inactiveSize} Deactivated Custom ${plural(inactiveSize, 'Role')}`}
            </div>
            <InactiveRoles roles={inactive} onToggleRole={onToggleRole} selectedRole={selectedRole} />
          </UL>
        </div>
      </Wrapper>
    </>
  );
}

const ActiveRoles = ({ roles, onToggleRole, selectedRole }) =>
  roles.map(cr => (
    <li key={cr.get('_id')} className={cn({ selected: selectedRole?.get('_id') === cr.get('_id') })}>
      <Link
        className="main-link"
        to={{
          pathname: navigate.to.SettingsCustomRoleNotifications({ customRoleId: cr.get('_id') }, false),
        }}
      >
        <span className="text-truncate">{cr.get('name')}</span>
      </Link>
      <TableRowDropDown deactivate onSelect={onToggleRole(cr)} />
    </li>
  ));

const InactiveRoles = ({ roles, onToggleRole, selectedRole }) =>
  roles.map(cr => (
    <li
      key={`cri-${cr.get('_id')}`}
      className={cn('inactive', { selected: selectedRole?.get('_id') === cr.get('_id') })}
    >
      <span className="text-gray-200">
        <FontAwesomeIcon icon="ban" className="mr-2" style={{ color: colors.red_30 }} />
        {cr.get('name')}
      </span>
      <TableRowDropDown reactivate onSelect={onToggleRole(cr)} />
    </li>
  ));
