import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../theme';
import { plural } from '../utils';
import { BulkContext } from './BulkActions.context';
import { emptyActions } from './common';

export function BulkHeader({ emptyTitle, show = false, actions = emptyActions, children, context, upcomingView }) {
  const { state, controller } = context;
  const selected = state.get('selectedTasks');
  const isEmpty = !selected.size;
  const hideLine = actions.length !== 2;
  const onClick = action => action(selected);

  return (
    <BulkContext.Provider value={context}>
      <Wrapper className={cn({ 'd-none': !show })} upcomingView={upcomingView}>
        {isEmpty ? (
          <div className="text-gray-400 font-size-16 empty-label emptyTitle">
            {emptyTitle}
            <div className="whiteBar"></div>
          </div>
        ) : (
          <div className="d-flex flex-row justify-content-between selected-wrapper emptyTitle">
            <div className="whiteBar"></div>
            <span className="task-counter">
              {`${selected.size} ${plural(selected.size, 'Task')} Selected`}
              <button className="ml-2 p-1 xmark" onClick={() => controller.clearSelectedTasks()}>
                <FontAwesomeIcon icon="xmark" className="font-size-12" />
              </button>
            </span>
            <div className="d-flex flex-row justify-content-between">
              <ActionButton {...actions[0]} onClick={onClick} />
              <ActionButton {...actions[1]} onClick={onClick} hideLine={hideLine} />
            </div>
          </div>
        )}
      </Wrapper>
      {children}
    </BulkContext.Provider>
  );
}

const ActionButton = ({ action, label, onClick, hideLine = true }) => {
  if (!action) return null;
  return (
    <>
      <VerticalLine className={cn({ 'd-none': hideLine })} />
      <button className="btn btn-link btn-action" onClick={() => onClick(action)}>
        {label}
      </button>
    </>
  );
};

const VerticalLine = styled.div`
  width: 1px;
  height: auto;
  background-color: ${colors.gray200};
`;

const Wrapper = styled.div`
  ${props => `
  top: ${props.upcomingView ? '24px' : '64px'};
 `}
    background-color: ${colors.blue_50};
    border-radius: 10px;

  padding: 0px;
  margin-bottom: 1rem;
  position: sticky;
  z-index: 10;

  & .emptyTitle {
    position: relative;
    border-radius: 10px;
    background-color: ${colors.blue_50};

    & .whiteBar {
      ${props => `
        top: ${props.upcomingView ? '-24px' : '-64px'};
      `}
      height: 80px;
      width: 101%;
      position: absolute;
      left: -5px;
      background-color: ${colors.white};
      z-index: -1;
    }
  }

  .empty-label {
    padding: 1rem;
  }
  .selected-wrapper {
    padding: 0.75rem;
  }
  .task-counter {
    font-size: 16px;
    background-color: ${colors.white};
    border-radius: 8px;
    padding: 0px 3px;
    color: ${colors.gray800};
  }
  button.xmark {
    height: 2rem;
    background: transparent;
    border: 0px;
    color: ${colors.gray800};
  }
  .btn-action {
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: bold;
  }
`;
