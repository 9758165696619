import React from 'react';
import cn from 'classnames';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../../../Button';
import { plural, datepickerFormatIncremented, formatTrx } from '../../../utils';
import { START_VIEW } from './UpdateRequestModal.context';
import { TaskList } from './TaskList';
import Datepicker from '../../../Datepicker';

const getEarlestDate = tasks => {
  const minDate = new Date(Math.min(...tasks.map(task => new Date(task.get('startDate')))));
  return datepickerFormatIncremented(formatTrx(minDate), 1);
};

export function ProposeDateView({ accept, cancel, state, isBuilder, controller }) {
  const { isTwoStep, startTasks, finishTasks, proposedStart, proposedFinish } = state.toObject();
  const size = startTasks.size + finishTasks.size;
  const isStartUR = state.get('view') === START_VIEW;

  const buttonText = isTwoStep ? (isStartUR ? 'Next' : `Send to Builder (${size} Tasks)`) : 'Send to Builder';
  const buttonTextBuilder = isTwoStep
    ? isStartUR
      ? 'Next'
      : `Confirm Date Change (${size} Tasks)`
    : 'Confirm Date Change';
  const tasksList = isStartUR ? startTasks : finishTasks;
  const minDate = isStartUR ? null : getEarlestDate(tasksList);
  const proposedDate = isStartUR ? proposedStart : proposedFinish;

  const setDate = date => {
    if (isStartUR) controller.setProposedStart(date);
    else controller.setProposedFinish(date);
  };

  const onClick = () => {
    if (isTwoStep && isStartUR) controller.setFinishView();
    else {
      const form = {
        start: {
          newStartDate: proposedStart,
          tasks: startTasks,
        },
        finish: {
          newEndDate: proposedFinish,
          tasks: finishTasks,
        },
      };
      accept({ form });
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <div className="d-flex flex-column align-items-start">
          <button
            onClick={() => controller.setStartView()}
            className={cn('btn text-muted position-relative', { 'd-none': !isTwoStep || isStartUR })}
            style={{ bottom: '0.5rem', right: '0.5rem' }}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </button>
          <Modal.Title>
            {isBuilder ? `Change ${state.get('view')} Date` : `Propose ${state.get('view')} Update Request`}
            {isTwoStep ? (
              <h6 className="font-weight-bold">
                {`${isStartUR ? startTasks.size : finishTasks.size} out of ${size} Tasks`}{' '}
              </h6>
            ) : (
              <h6 className="font-weight-bold">{`${size} ${plural(size, 'Task')}`}</h6>
            )}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="py-0">
        <DateSelect
          proposeType={state.get('view')}
          setDate={setDate}
          proposedDate={proposedDate}
          minDate={minDate}
          isBuilder={isBuilder}
        />
        <TaskList tasks={tasksList} />
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton variant="secondary" className="mr-3" onClick={() => cancel()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={onClick}>{isBuilder ? buttonTextBuilder : buttonText}</PrimaryButton>
      </Modal.Footer>
    </>
  );
}

const DateSelect = ({ proposeType, proposedDate, minDate, setDate, isBuilder }) => {
  return (
    <>
      <div className="font-size-14">{` ${isBuilder ? 'Expected' : 'Propose'} ${proposeType}`}</div>
      <Datepicker
        name="proposedDate"
        selected={proposedDate}
        minDate={minDate}
        //   readOnly={showPopover}
        //   dateFormat={`MMM dd${isYearDisplayed ? ', yyyy' : ''}`}
        dateFormat={`MMM dd`}
        onChange={date => setDate(date)}
        //   onFocus={() => setIsYearDisplayed(true)}
        //   onBlur={({ target }) => setIsYearDisplayed(isDifferentYear(Date.parse(target.value)))}
        //   dayClassName={date => criticalProposedDateClass(date, selectedDate, expectedDate, criticalDate)}
        //   renderDayContents={(day, date) => CriticalDay(day, date, criticalDate, selectedDate)}
      />
    </>
  );
};
